<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'usersCrud'"
          crudName="Produtos"
          newText="Novo Produto"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="true"
          :enableDelete="true"
          :enableView="false"
          :enableReset="false"
          :dataForm="dataForm"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      table: "produtoTable",
      showMessageImport: false,
      usersCrud: {},
      pages: [],
      url: "",
      columns: [
        "produto_id",
        "produto_nome",
        "categoria_produto_id",
        "produto_quantidade",
        "tipo_unidade_medida_id",
        "observacao",
        "status",
        "actions",
      ],
      tableData: [
        "produto_id",
        "produto_nome",
        "categoria_produto_id",
        "produto_quantidade",
        "tipo_unidade_medida_id",
        "observacao",
        "status",
      ],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: [
          "produto_id",
          "produto_nome",
          "produto_quantidade",
          "tipo_unidade_medida_id",
        ],
        sortable: [
          "produto_id",
          "produto_nome",
          "produto_quantidade",
          "tipo_unidade_medida_id",
          "status",
        ],
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {
          status: [
            {
              id: 1,
              text: "Ativo",
            },
            {
              id: 0,
              text: "Inativo",
            },
          ],
        },
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          produto_id: "ID",
          produto_nome: "Nome do Produto",
          categoria_produto_id: "Categoria",
          produto_quantidade: "Quantidade de Produto",
          tipo_unidade_medida_id: "Tipo de Unidade de Medida",
          oservacao: "Observação",
          status: "Status",
          actions: "Ações",
        },
        templates: {
          tipo_unidade_medida_id: function (h, row, index) {
            return row.tipo_unidade_medida_id
              ? row.unidade_medida.tipo_unidade_medida_nome
              : "Sem Nome";
          },
          categoria_produto_id: function (h, row, index) {
            return row.categoria_produto_id
              ? row.categoria_produto.categoria_produto_nome
              : "Sem Nome";
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          console.log(this.$parent.$parent);

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ["unidade_medida", "categoria_produto"];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "produtos",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },
    importFile() {
      const self = this;
      let api = self.$store.state.api + "import-users";

      let fd = self.makeFormData();

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.showMessageImport = true;
          self.$refs.usersCrud.$refs.table.refresh();
        })
        .catch((error) => {
          self.$message(
            "Ops",
            "Houve um erro durante a importação da planilha, verifique a planilha e tente novamente.",
            "error"
          );
        });
    },
    query: function (query) {
      let columns = {
        id: "produto_id",
        categoria_produto_id: "categoria_produto_id",
        produto_quantidade: "produto_quantidade",
        tipo_unidade_medida_id: "tipo_unidade_medida_id",
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    openInput() {
      document.getElementById("fileImport").click();
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>