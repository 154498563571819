<template>
  <div>
    <div class="main-content" v-if="!isLoading">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <div class="card-title">Solicitação de Transporte</div>
              <div class="card-title" v-if="entity.flag_recorrencia">
                <span :style="'color:' +
                  (entity.duplicado_recorrencia ? '#8800aa;' : '#aa5588;')
                  ">
                  <span class="badge" style="background: #8800aa; border-radius: 100px"
                    v-if="entity.duplicado_recorrencia">R</span>
                  <span class="badge" style="background: #aa5588; border-radius: 100px"
                    v-if="!entity.duplicado_recorrencia">1º</span>
                  Solicitação recorrente
                </span>
              </div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-md-12">
                  <div class="b-card-subtitle">
                    Linha do Tempo da Solicitação
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-4" v-if="entity.status_solicitacao != 4">
                  <ol class="progress2" data-steps="4">
                    <li :class="entity.status_solicitacao > 0
                      ? 'done'
                      : entity.status_solicitacao == 0
                        ? 'active'
                        : ''
                      ">
                      <span class="name">Solicitado</span>
                      <span class="step"><span>1</span></span>
                      <span class="step-description">
                        {{ entity.data_solicitacao }}<br />
                        {{ entity.solicitante.profissional_nome }}
                      </span>
                    </li>
                    <li :class="entity.status_solicitacao > 1
                      ? 'done'
                      : entity.status_solicitacao == 1
                        ? 'active'
                        : ''
                      ">
                      <span class="name">Aprovado</span>
                      <span class="step"><span>2</span></span>
                      <span class="step-description">
                        {{ entity.data_aprovacao ? entity.data_aprovacao : ""
                        }}<br />
                        {{
                          entity.usuario_aprovacao
                          ? entity.usuario_aprovacao.usuario_nome
                          : ""
                        }}
                      </span>
                    </li>
                    <li :class="entity.status_solicitacao > 2
                          ? 'done'
                          : entity.status_solicitacao == 2
                            ? 'active'
                            : ''
                        ">
                      <span class="name">Programado</span>
                      <span class="step"><span>3</span></span>
                      <span class="step-description">{{
                        entity.data_programacao
                      }}</span>
                    </li>
                    <li :class="entity.status_solicitacao > 3
                          ? 'done'
                          : entity.status_solicitacao == 3
                            ? 'active'
                            : ''
                        ">
                      <span class="name">Finalizado</span>
                      <span class="step"><span>4</span></span>
                      <span class="step-description">{{
                        entity.data_finalizacao
                      }}</span>
                    </li>
                  </ol>
                </div>
                <div class="col-md-12" v-else>
                  <div class="alert alert-danger" role="alert">
                    Esta solicitação foi cancelada por:
                    <strong>{{
                      entity.usuario_cancelamento
                      ? entity.usuario_cancelamento.usuario_nome
                      : ""
                    }}</strong> em
                    {{ entity.data_cancelamento }}<br /><br />
                    <strong>Justificativa:</strong>
                    {{ entity.justificativa_cancelamento }}<br /><br />
                    <strong>Motivo do Cancelamento:</strong>
                    {{ entity.cancelamento.cancelamento_nome }}
                  </div>
                </div>
              </div>
              <div class="row mb-2 mt-4" v-if="veiculos_enviados.length > 0">
                <div class="col-md-12">
                  <div class="b-card-subtitle">Veículos Enviados</div>
                </div>
              </div>
              <div class="row mb-2" v-if="veiculos_enviados.length > 0">
                <div class="col-md-12">
                  <div class="row mt-1" v-for="veiculo in veiculos_enviados" :key="veiculo.envio_veiculo_id">
                    <div class="col-md-1 mt-2">
                      <button class="btn btn-crud delete" @click="deletarVeiculo(veiculo)">
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </div>
                    <div class="col-md-2 mt-2" style="font-weight: bold; margin-left: -50px">
                      {{ veiculo.veiculo.nome_veiculo }} -
                      {{ veiculo.veiculo.placa_veiculo }}
                    </div>
                    <div class="col-md-2">
                      <label>Última Posição</label>
                      <input disabled type="text" class="form-control" :value="veiculo.ultima_posicao" />
                    </div>
                    <div class="col-md-2">
                      <label>Data/Hora</label>
                      <input disabled type="datetime-local" class="form-control" :value="veiculo.data_ultima_posicao" />
                    </div>
                    <div class="col-md-2 mt-2">
                      <button class="btn btn-primary" data-toggle="modal" data-target="#modalLancamento"
                        @click="movimentacao.veiculo = veiculo">
                        Movimentar
                      </button>
                    </div>
                    <div class="col-md-2 ml-2 mt-2">
                      <button class="btn btn-primary" data-toggle="modal" data-target="#modalMovimentacoes"
                        @click="setHistorico(veiculo.posicoes)">
                        Histórico
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <!-- <div
                      class="col-md-1"
                      style="border-right: 1px solid #e4e4e4"
                    >
                      <div
                        class="row pd-item"
                        :class="page == 1 ? 'selected' : ''"
                        @click="page = 1"
                      >
                        <div class="col-md-12">
                          <i class="fas fa-id-card"></i>
                        </div>
                      </div>
                      <div
                        class="row pd-item"
                        :class="page == 2 ? 'selected' : ''"
                        @click="page = 2"
                      >
                        <div class="col-md-12">
                          <i class="fas fa-person-booth"></i>
                        </div>
                      </div>
                      <div
                        class="row pd-item"
                        :class="page == 3 ? 'selected' : ''"
                        @click="page = 3"
                      >
                        <div class="col-md-12">
                          <i class="fas fa-tasks"></i>
                        </div>
                      </div>
                      <div
                        class="row pd-item"
                        :class="page == 4 ? 'selected' : ''"
                        @click="page = 4"
                      >
                        <div class="col-md-12">
                          <i class="fas fa-stethoscope"></i>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-md-12">
                      <section>
                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Informações da Solicitação
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-3">
                            <label>Número da Solicitação</label>
                            <input disabled type="text" class="form-control" v-model="entity.numero_solicitacao" />
                          </div>
                          <div class="col-md-3">
                            <label>Data e Hora da Solicitação</label>
                            <input disabled type="datetime-local" class="form-control"
                              v-model="entity.data_solicitacao" />
                          </div>
                          <div class="col-md-3">
                            <label>Data e Hora do Agendamento</label>
                            <input disabled type="datetime-local" class="form-control"
                              v-model="entity.data_procedimento" />
                          </div>
                          <div class="col-md-3">
                            <label>Tipo de Solicitação</label>
                            <input disabled type="text" class="form-control" v-model="entity.tipo_transporte.tipo_transporte_nome
                              " />
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-12">
                            <label>Motivo do Solicitação</label>
                            <input disabled type="text" class="form-control" v-model="entity.motivo_transporte.motivo_transporte_nome
                              " />
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-12">
                            <label>Observações do Transporte</label>
                            <textarea disabled class="form-control" cols="30" rows="3"
                              v-model="entity.observacao_transporte"></textarea>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-6">
                            <toggle-button v-model="entity.flag_mandado_judicial" color="#15488A" :height="25" disabled />
                            É mandado judicial?
                          </div>
                        </div>
                        <div class="row mt-1" v-if="entity.flag_mandado_judicial">
                          <div class="col-md-3">
                            <label><span class="required">*</span> Número do
                              processo</label>
                            <input type="text" class="form-control" v-model="entity.mj_numero_processo" disabled />
                          </div>

                          <div class="col-md-3">
                            <label>Data da liberação da vaga</label>
                            <input type="date" class="form-control" v-model="entity.mj_data_liberacao_vaga_f" disabled />
                          </div>
                          <div class="col-md-3">
                            <label> Data da sentença</label>
                            <input type="date" class="form-control" v-model="entity.mj_data_sentenca" disabled />
                          </div>
                          <div class="col-md-3">
                            <label>Juiz</label>
                            <input type="text" class="form-control" v-model="entity.mj_juiz" disabled />
                          </div>
                          <div class="col-md-12 mt-1">
                            <label> Resumo da Sentença</label>
                            <input type="text" class="form-control" v-model="entity.mj_resumo_sentenca" disabled />
                          </div>
                        </div>
                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">Solicitante</div>
                          </div>
                        </div>

                        <div class="row" v-if="configuracoes.setor_solicitacao == 1 &&
                          configuracoes.telefone_solicitacao == 1 &&
                          configuracoes.profissional_solicitacao == 1
                          ">
                          <div class="col-md-4">
                            <label><span class="required">*</span> Responsável pela
                              abertura da solicitação</label>
                            <input disabled type="text" class="form-control"
                              v-model="entity.solicitante.profissional_nome" />
                          </div>
                          <div class="col-md-4">
                            <label>Setor</label>
                            <input disabled type="text" class="form-control" v-model="entity.solicitante.unidade_solicitante_setor
                              " />
                          </div>
                          <div class="col-md-4">
                            <label>Telefone</label>
                            <input disabled type="text" class="form-control" v-mask="'(99) 99999-9999'" v-model="entity.solicitante.unidade_solicitante_telefone
                              " />
                          </div>
                          <!-- <div class="col-md-4" @click="getCbo">
                            <label>CBO</label>
                            <v-select
                              @click="getCbo"
                              :options="cbos"
                              label="cbo_nome"
                              v-model="entity.cbo_id"
                              :reduce="(cbo) => cbo.cbo_id"
                            >
                              <div slot="no-options">
                                Aguarde, carregando lista de CBO's
                              </div>
                            </v-select>
                          </div> -->
                          <div class="col-md-4">
                            <label>Nome do médico solicitante</label>
                            <input disabled type="text" class="form-control"
                              v-model="entity.solicitante.medico_solicitante" />
                          </div>
                          <!-- <div class="col-md-4 mt-1">
                            <label>ID Funcional</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.id_funcional"
                            />
                          </div> -->
                          <div class="col-md-4">
                            <label>Número do Conselho</label>
                            <input disabled type="text" class="form-control"
                              v-model="entity.solicitante.numero_conselho" />
                          </div>
                          <div class="col-md-4">
                            <label>Conselho de Classe</label>
                            <v-select disabled :options="conselho_classe" label="nome"
                              v-model="entity.solicitante.conselho_classe">
                              <div slot="no-options">
                                Aguarde, carregando lista de conselhos
                              </div>
                            </v-select>
                          </div>
                          <div class="col-md-6 mt-1">
                            <label>Unidade Solicitante</label>
                            <v-select disabled :options="unidade_saude" :reduce="(unidade_saude) => unidade_saude.id"
                              label="unidade_saude_nome" v-model="entity.solicitante.unidade_solicitante_id
                                "></v-select>
                          </div>
                        </div>

                        <div class="row" v-if="configuracoes.setor_solicitacao == 0 &&
                          configuracoes.telefone_solicitacao == 0 &&
                          configuracoes.profissional_solicitacao == 0
                          ">
                          <div class="col-md-4">
                            <label><span class="required">*</span> Nome do
                              Profissional</label>
                            <input type="text" disabled class="form-control"
                              v-model="entity.solicitante.profissional_nome" />
                          </div>
                          <div class="col-md-4">
                            <label>CBO</label>
                            <input v-if="entity.solicitante.cbo" type="text" disabled class="form-control"
                              v-model="entity.solicitante.cbo.cbo_nome" />
                            <input v-else type="text" disabled class="form-control" />
                          </div>
                          <div class="col-md-4">
                            <label>CNS</label>
                            <input disabled type="text" class="form-control"
                              v-model="entity.solicitante.profissional_cns" />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>ID Funcional</label>
                            <input disabled type="text" class="form-control" v-model="entity.solicitante.id_funcional" />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Número do Conselho</label>
                            <input disabled type="text" class="form-control"
                              v-model="entity.solicitante.numero_conselho" />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Conselho de Classe</label>
                            <v-select disabled :options="conselho_classe" label="nome"
                              v-model="entity.solicitante.conselho_classe">
                              <div slot="no-options">
                                Aguarde, carregando lista de conselhos
                              </div>
                            </v-select>
                          </div>
                          <div class="col-md-6 mt-1">
                            <label>Unidade Solicitante</label>
                            <v-select disabled :options="unidade_saude" :reduce="(unidade_saude) => unidade_saude.id"
                              label="unidade_saude_nome" v-model="entity.solicitante.unidade_solicitante_id
                                "></v-select>
                          </div>
                          <div class="col-md-3 mt-1">
                            <label>Setor</label>
                            <input disabled type="text" class="form-control" v-model="entity.solicitante.unidade_solicitante_setor
                              " />
                          </div>
                          <div class="col-md-3 mt-1">
                            <label>Telefone</label>
                            <input disabled type="text" class="form-control" v-mask="'(99) 99999-9999'" v-model="entity.solicitante.unidade_solicitante_telefone
                              " />
                          </div>
                        </div>

                        <div class="row mb-2 mt-3">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Endereço de Origem
                            </div>
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-md-6">
                            <label>Tipo de Endereço</label>
                            <select disabled class="form-control" :value="entity.origem
                              ? entity.origem.tipo_estabelecimento_id
                              : ''
                              ">
                              <option v-for="tipo in tipos_domicilio" :key="tipo.tipo_domicilio_id"
                                :value="tipo.tipo_domicilio_id">
                                {{ tipo.tipo_domicilio_nome }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mt-1" v-if="entity.origem.tipo_estabelecimento_id == 6">
                          <div class="col-md-6">
                            <label>Unidade de Origem</label>
                            <v-select disabled :options="unidade_saude" label="unidade_saude_nome" :value="entity.origem
                              ? entity.origem.unidade_saude_id
                              : ''
                              " v-on:input="selectUnidadeOrigem"></v-select>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-2">
                            <label>CEP da Origem</label>
                            <input disabled :value="entity.origem ? entity.origem.cep : ''" type="tel"
                              class="form-control" v-mask="'99999-999'" />
                          </div>
                          <div class="col-md-5">
                            <label>Logradouro</label>
                            <input disabled :value="entity.origem ? entity.origem.endereco : ''
                              " type="text" class="form-control" />
                          </div>
                          <div class="col-md-2">
                            <label>Número</label>
                            <input disabled id="inputNumero" :value="entity.origem ? entity.origem.numero : ''"
                              type="text" class="form-control" />
                          </div>
                          <div class="col-md-3">
                            <label>Bairro</label>
                            <input disabled :value="entity.origem ? entity.origem.bairro : ''" type="text"
                              class="form-control" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-2 mt-1">
                            <label>Estado</label>
                            <v-select disabled :options="ufs" :reduce="(ufs) => ufs.id" :label="'uf_nome'"
                              v-on:input="getEstadoMunicipioOrigem" :value="entity.origem ? entity.origem.estado_id : ''
                                " class="vselect" />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Município</label>
                            <v-select disabled :options="municipios_origem" :reduce="(municipios) => municipios.id"
                              :label="'municipio_nome'" :value="entity.origem ? entity.origem.municipio_id : ''
                                " class="vselect" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 mt-1">
                            <label>Complemento</label>
                            <input disabled :value="entity.origem ? entity.origem.complemento : ''
                              " type="text" class="form-control" />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Ponto de Referência</label>
                            <input disabled :value="entity.origem
                              ? entity.origem.ponto_referencia
                              : ''
                              " type="text" class="form-control" />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Telefone</label>
                            <input disabled :value="entity.origem ? entity.origem.telefone : ''
                              " type="tel" class="form-control" v-mask="'(99) 99999-9999'" />
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-6 mt-1">
                            <label><span class="required">*</span> Possui
                              dificuldade de acesso ao local?</label>
                            <select class="form-control" v-model="entity.origem.dificuldade_acesso" disabled>
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div class="col-md-6 mt-1" v-if="entity.origem.dificuldade_acesso == 'SIM'">
                            <label><span class="required">*</span> Qual?</label>
                            <input v-model="entity.origem.dificuldade_acesso_descricao
                              " type="text" class="form-control" disabled />
                          </div>
                        </div>
                        <div class="row mt-1" v-if="entity.origem.tipo_estabelecimento_id == 6">
                          <div class="col-md-4">
                            <label>Setor</label>
                            <input :value="entity.origem ? entity.origem.setor : ''" type="text" class="form-control"
                              disabled />
                          </div>
                          <div class="col-md-4">
                            <label>Pavimento</label>
                            <input :value="entity.origem ? entity.origem.pavimento : ''
                              " type="text" class="form-control" disabled />
                          </div>
                          <div class="col-md-4">
                            <label>Leito</label>
                            <input :value="entity.origem ? entity.origem.leito : ''" type="text" class="form-control"
                              disabled />
                          </div>
                        </div>

                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Endereço de Destino
                            </div>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-6">
                            <label>Tipo de Endereço</label>
                            <select disabled class="form-control" :value="entity.destino
                              ? entity.destino.tipo_estabelecimento_id
                              : ''
                              ">
                              <option disabled v-for="tipo in tipos_domicilio" :key="tipo.tipo_domicilio_id"
                                :value="tipo.tipo_domicilio_id">
                                {{ tipo.tipo_domicilio_nome }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-6">
                            <label>Tipo de Destino</label>
                            <select disabled class="form-control" v-model="entity.tipo_destino">
                              <option value="Municipal">Municipal</option>
                              <option value="Intermunicipal">
                                Intermunicipal
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="row mt-1" v-if="entity.origem.tipo_estabelecimento_id == 6">
                          <div class="col-md-6">
                            <label>Unidade de Destino</label>
                            <v-select disabled :options="unidade_saude" label="unidade_saude_nome" :value="entity.destino
                              ? entity.destino.unidade_saude_id
                              : ''
                              " v-on:input="selectUnidadeDestino"></v-select>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-2">
                            <label>CEP do Destino</label>
                            <input disabled type="text" class="form-control" id="cepUnidade" v-mask="'99999-999'"
                              :value="entity.destino ? entity.destino.cep : ''" />
                          </div>
                          <div class="col-md-5">
                            <label>Logadouro</label>
                            <input disabled :value="entity.destino ? entity.destino.endereco : ''
                              " type="text" class="form-control" />
                          </div>
                          <div class="col-md-2">
                            <label>Número</label>
                            <input disabled id="inputNumeroDestino" :value="entity.destino ? entity.destino.numero : ''
                              " type="text" class="form-control" />
                          </div>
                          <div class="col-md-3">
                            <label>Bairro</label>
                            <input disabled :value="entity.destino ? entity.destino.bairro : ''
                              " type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-2 mt-1">
                            <label>Estado</label>
                            <v-select disabled :options="ufs" :reduce="(ufs) => ufs.id" :label="'uf_nome'"
                              v-on:input="getEstadoMunicipioDestino" :value="entity.destino ? entity.destino.estado_id : ''
                                " class="vselect" />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Município</label>
                            <v-select disabled :options="municipios_destino" :reduce="(municipios) => municipios.id"
                              :label="'municipio_nome'" :value="entity.destino
                                ? entity.destino.municipio_id
                                : ''
                                " class="vselect" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 mt-1">
                            <label>Complemento</label>
                            <input disabled :value="entity.destino ? entity.destino.complemento : ''
                              " type="text" class="form-control" />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Ponto de Referência</label>
                            <input disabled :value="entity.destino
                              ? entity.destino.ponto_referencia
                              : ''
                              " type="text" class="form-control" />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Telefone</label>
                            <input disabled :value="entity.destino ? entity.destino.telefone : ''
                              " type="tel" class="form-control" v-mask="'(99) 99999-9999'" />
                          </div>
                        </div>

                        <div class="row mt-1" v-if="entity.origem.tipo_estabelecimento_id == 6">
                          <div class="col-md-4">
                            <label>Setor</label>
                            <input :value="entity.destino ? entity.destino.setor : ''
                              " type="text" class="form-control" disabled />
                          </div>
                          <div class="col-md-4">
                            <label>Pavimento</label>
                            <input :value="entity.destino ? entity.destino.pavimento : ''
                              " type="text" class="form-control" disabled />
                          </div>
                          <div class="col-md-4">
                            <label>Leito</label>
                            <input :value="entity.destino ? entity.destino.leito : ''
                              " type="text" class="form-control" disabled />
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-md-12">
                            <label>Nome do Profissional Responsável pela
                              Autorização</label>
                            <input :value="entity.destino
                              ? entity.destino.nome_profissional_autorizacao
                              : ''
                              " disabled type="text" class="form-control" />
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-md-6">
                            <div class="custom-control custom-switch">
                              <input disabled autocomplete="new-password" v-model="entity.flag_retorna_origem"
                                placeholder="" type="checkbox" class="custom-control-input" id="customSwitch1" />
                              <label class="custom-control-label" for="customSwitch1">Retorna para Origem?</label>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section v-if="entity.tipo_transporte_id != 2">
                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Informações do Paciente
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <label>CPF</label>
                            <input disabled :value="entity.paciente ? entity.paciente.cpf : ''
                              " type="text" class="form-control" v-mask="'999.999.999-99'" />
                          </div>
                          <div class="col-md-6">
                            <label>CNS</label>
                            <input disabled :value="entity.paciente ? entity.paciente.cns : ''
                              " type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-4">
                            <label>Nome</label>
                            <input disabled v-model="entity.paciente.nome_paciente" type="text" class="form-control" />
                          </div>
                          <div class="col-md-4">
                            <label>Nome Social</label>
                            <input disabled v-model="entity.paciente.nome_social" type="text" class="form-control" />
                          </div>
                          <div class="col-md-4">
                            <label>Gênero</label>
                            <select disabled class="form-control" v-model="entity.paciente.sexo">
                              <option value="M">MASCULINO</option>
                              <option value="F">FEMININO</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-4">
                            <label>Data de Nascimento</label>
                            <input disabled v-model="entity.paciente.data_nascimento" type="date" class="form-control" />
                          </div>
                          <div class="col-md-4">
                            <label>Telefone</label>
                            <input disabled v-model="entity.paciente.telefone" type="tel" class="form-control"
                              v-mask="'(99) 99999-9999'" />
                          </div>
                          <div class="col-md-4">
                            <label>Cor/Raça</label>
                            <select disabled class="form-control" v-model="entity.paciente.raca">
                              <option value="BRANCA">BRANCA</option>
                              <option value="PRETA">PRETA</option>
                              <option value="PARDA">PARDA</option>
                              <option value="AMARELA">AMARELA</option>
                              <option value="INDÍGENA">INDÍGENA</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-6">
                            <label>Nome do Pai</label>
                            <input disabled v-model="entity.paciente.nome_pai" type="tel" class="form-control" />
                          </div>
                          <div class="col-md-6">
                            <label>Nome da Mãe</label>
                            <input disabled v-model="entity.paciente.nome_mae" type="tel" class="form-control" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mt-1">
                            <label>Possui dificuldade de acesso ao entrar ou sair do
                              domicílio?</label>
                            <select disabled class="form-control" v-model="entity.paciente.dificuldade_acesso_domicilio
                              ">
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div class="col-md-6 mt-1" v-if="entity.paciente.dificuldade_acesso_domicilio ==
                            'SIM'
                            ">
                            <label>Qual?</label>
                            <input disabled v-model="entity.paciente
                              .dificuldade_acesso_domicilio_descricao
                              " type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mt-1">
                            <label>Possui dificuldade de acesso ao entrar ou sair do
                              paciente?</label>
                            <select disabled class="form-control" v-model="entity.paciente.dificuldade_acesso_paciente
                              ">
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div class="col-md-6 mt-1" v-if="entity.paciente.dificuldade_acesso_paciente ==
                            'SIM'
                            ">
                            <label>Qual?</label>
                            <input disabled v-model="entity.paciente
                              .dificuldade_acesso_paciente_descricao
                              " type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mt-1">
                            <label>Paciente possui acompanhante?</label>
                            <select disabled class="form-control" v-model="entity.possui_acompanhante">
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div class="col-md-6 mt-1" v-if="entity.possui_acompanhante == 'SIM'">
                            <label>Tipo do Acompanhante</label>
                            <select disabled class="form-control" v-model="entity.acompanhante.tipo_acompanhante_id">
                              <option v-for="tiac in tipos_acompanhante" :key="tiac.tipo_acompanhante_id"
                                :value="tiac.tipo_acompanhante_id">
                                {{ tiac.tipo_acompanhante_nome }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mt-1" v-if="entity.possui_acompanhante == 'SIM'">
                          <div class="col-md-4">
                            <label>Nome</label>
                            <input disabled v-model="entity.acompanhante.acompanhante_nome" type="text"
                              class="form-control" />
                          </div>
                          <div class="col-md-4">
                            <label>Telefone</label>
                            <input disabled v-model="entity.acompanhante.acompanhante_telefone
                              " type="tel" class="form-control" v-mask="'(99) 99999-9999'" />
                          </div>
                          <div class="col-md-4">
                            <label>CPF</label>
                            <input disabled :value="entity.acompanhante
                              ? entity.acompanhante.acompanhante_cpf
                              : ''
                              " type="tel" class="form-control" v-mask="'999.999.999-99'" />
                          </div>
                        </div>
                      </section>

                      <section v-if="entity.tipo_transporte_id != 2">
                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Condições Clínicas do Paciente
                            </div>
                          </div>
                        </div>

                        <div class="row mt-3" v-if="!configuracoes.sinais_vitais">
                          <div class="col-md-12">
                            <h3>Antropometria</h3>
                          </div>

                          <div class="col-md-2">
                            <label for="">Peso</label>
                            <input type="number" class="form-control" id="" v-model="entity.paciente.peso" disabled />
                          </div>
                          <div class="col-md-2">
                            <label for="">Altura</label>
                            <input type="text" class="form-control" id="" v-model="entity.paciente.altura" v-mask="'#.##'"
                              disabled />
                          </div>
                          <div class="col-md-2">
                            <label for="">IMC</label>
                            <input disabled type="text" class="form-control" :value="imc ? imc : ''" />
                          </div>
                          <div class="col-ml-1" style="margin-top: 5px">
                            <table class="tabela-imc">
                              <tr>
                                <th v-if="entity.paciente.peso &&
                                  entity.paciente.altura &&
                                  imc
                                  ">
                                  Classificação
                                </th>
                                <th v-if="entity.paciente.peso &&
                                  entity.paciente.altura &&
                                  imc
                                  ">
                                  IMC
                                </th>
                              </tr>
                              <tr style="
                                  background-color: #ffff00;
                                  color: #000000;
                                " v-if="imc < 18.5 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                  ">
                                <td>Abaixo do peso</td>
                                <td>Menor que 18,5</td>
                              </tr>
                              <tr style="
                                  background-color: #add8e6;
                                  color: #000000;
                                " v-if="imc >= 18.5 &&
                                  imc <= 24.9 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                  ">
                                <td>Peso normal</td>
                                <td>18,5 - 24,9</td>
                              </tr>
                              <tr style="
                                  background-color: #ffa500;
                                  color: #000000;
                                " v-if="imc >= 25 &&
                                  imc <= 29.9 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                  ">
                                <td>Sobrepeso</td>
                                <td>25 - 29,9</td>
                              </tr>
                              <tr style="
                                  background-color: #ff4500;
                                  color: #000000;
                                " v-if="imc >= 30 &&
                                  imc <= 34.9 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                  ">
                                <td>Obesidade grau 1</td>
                                <td>30 - 34,9</td>
                              </tr>
                              <tr style="
                                  background-color: #ff0000;
                                  color: #000000;
                                " v-if="imc >= 35 &&
                                  imc <= 39.9 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                  ">
                                <td>Obesidade grau 2</td>
                                <td>35 - 39,9</td>
                              </tr>
                              <tr style="
                                  background-color: #e30505;
                                  color: #000000;
                                " v-if="imc >= 40 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                  ">
                                <td>Obesidade grau 3</td>
                                <td>Maior ou igual a 40</td>
                              </tr>
                            </table>
                          </div>
                        </div>

                        <div class="row mb-1" v-if="configuracoes.sinais_vitais == 1">
                          <div class="col-md-4">
                            <label for="">Estado Neurológico</label>
                            <select class="form-control" v-model="entity.paciente.estado_neurologico" disabled>
                              <option value="Alerta">Alerta</option>
                              <option value="Sonolento">Sonolento</option>
                              <option value="Torporoso">Torporoso</option>
                              <option value="Sedado">Sedado</option>
                              <option value="Inconsciente">Inconsciente</option>
                            </select>
                          </div>
                        </div>

                        <div class="row" v-if="configuracoes.sinais_vitais == 1">
                          <div class="col-md-2">
                            <label for="">Glicemia</label>
                            <input type="number" @input="limitarValor" placeholder="Máximo de 1000" class="form-control"
                              v-model="entity.paciente.glicemia" disabled />
                          </div>
                          <div class="col-md-2" style="margin-top: 30px">
                            <span class="boxSelecionavel" :class="entity.paciente.glicemia_tipo == 'Em Jejum'
                              ? 'ativo'
                              : ''
                              ">Em Jejum</span>
                            <span class="boxSelecionavel" :class="entity.paciente.glicemia_tipo == 'Pós-prandial'
                              ? 'ativo'
                              : ''
                              ">Pós-prandial</span>
                          </div>
                        </div>
                        <div class="row mt-4" v-if="configuracoes.sinais_vitais == 1">
                          <div class="col-md-12">
                            <h3>Sinais vitais</h3>
                          </div>
                          <div class="col-md-12 mt-1">
                            <div class="row">
                              <div class="col-md-4 bold">Pressão arterial</div>
                              <div class="col-md-2 bold">Temperatura</div>
                              <div class="col-md-2 bold">FR</div>
                              <div class="col-md-2 bold">FC</div>
                              <div class="col-md-2 bold"></div>
                            </div>
                            <div class="row">
                              <div class="col-md-2">
                                <label for="pa_sistolica">Sist. (mmHg):</label>
                                <input type="number" id="pa_sistolica" v-model.number="entity.paciente.pa_sistolica"
                                  class="form-control" placeholder="Máximo de 300" disabled />
                              </div>
                              <div class="col-md-2">
                                <label for="">Diast. (mmHg)</label>
                                <input type="number" class="form-control" v-model="entity.paciente.pa_diastolica"
                                  placeholder="Máximo de 300" disabled />
                              </div>
                              <div class="col-md-2">
                                <label for="">C°</label>
                                <input type="number" class="form-control" v-model="entity.paciente.temperatura"
                                  placeholder="Máximo de 60" disabled />
                              </div>
                              <div class="col-md-2">
                                <label for="">(rpm)</label>
                                <input type="number" disabled placeholder="Máximo de 300" class="form-control"
                                  v-model="entity.paciente.fr" />
                              </div>
                              <div class="col-md-2">
                                <label for="">(bpm)</label>
                                <input type="number" disabled placeholder="Máximo de 300" class="form-control"
                                  v-model="entity.paciente.fc" />
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-2">
                                <label for="">Saturação</label>
                                <input type="number" disabled placeholder="Máximo de 100" class="form-control"
                                  v-model="entity.paciente.saturacao" />
                              </div>
                              <div class="col-md-3" style="margin-top: 30px">
                                <span class="boxSelecionavel" :class="entity.paciente.saturacao_tipo ==
                                  'Ar Atmosférico'
                                  ? 'ativo'
                                  : ''
                                  ">Ar Atmosférico</span>
                                <span class="boxSelecionavel" :class="entity.paciente.saturacao_tipo ==
                                  'Em terapia de O2'
                                  ? 'ativo'
                                  : ''
                                  ">Em terapia de O2</span>
                              </div>
                            </div>
                            <div class="row mt-2" v-if="diffIdadeDias <= 29">
                              <div class="col-md-2">
                                <label for="">APGAR (1 minuto)</label>
                                <input type="number" disabled placeholder="Máximo de 10" class="form-control"
                                  v-model="entity.paciente.apgar_1" />
                              </div>
                              <div class="col-md-2">
                                <label for="">APGAR (5 minutos)</label>
                                <input type="number" disabled placeholder="Máximo de 10" class="form-control"
                                  v-model="entity.paciente.apgar_2" />
                              </div>
                            </div>
                            <div class="row mt-2" v-if="diffIdadeDias <= 29">
                              <div class="col-md-2">
                                <label for="">Idade Gestacional</label>
                                <input type="number" disabled placeholder="Máximo de 45" class="form-control"
                                  v-model="entity.paciente.idade_gestacional" />
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-12">
                                <h3>Antropometria</h3>
                              </div>

                              <div class="col-md-2">
                                <label for="">Peso</label>
                                <input type="number" class="form-control" id="" v-model="entity.paciente.peso" disabled />
                              </div>
                              <div class="col-md-2">
                                <label for="">Altura</label>
                                <input type="text" class="form-control" id="" v-model="entity.paciente.altura" disabled
                                  v-mask="'#.##'" />
                              </div>
                              <div class="col-md-2">
                                <label for="">IMC</label>
                                <input disabled type="text" class="form-control" :value="imc ? imc : ''" />
                              </div>
                              <div class="col-ml-1" style="margin-top: 5px">
                                <table class="tabela-imc">
                                  <tr>
                                    <th v-if="entity.paciente.peso &&
                                      entity.paciente.altura &&
                                      imc
                                      ">
                                      Classificação
                                    </th>
                                    <th v-if="entity.paciente.peso &&
                                      entity.paciente.altura &&
                                      imc
                                      ">
                                      IMC
                                    </th>
                                  </tr>
                                  <tr style="
                                      background-color: #ffff00;
                                      color: #000000;
                                    " v-if="imc < 18.5 &&
                                      entity.paciente.peso &&
                                      entity.paciente.altura
                                      ">
                                    <td>Abaixo do peso</td>
                                    <td>Menor que 18,5</td>
                                  </tr>
                                  <tr style="
                                      background-color: #add8e6;
                                      color: #000000;
                                    " v-if="imc >= 18.5 &&
                                      imc <= 24.9 &&
                                      entity.paciente.peso &&
                                      entity.paciente.altura
                                      ">
                                    <td>Peso normal</td>
                                    <td>18,5 - 24,9</td>
                                  </tr>
                                  <tr style="
                                      background-color: #ffa500;
                                      color: #000000;
                                    " v-if="imc >= 25 &&
                                      imc <= 29.9 &&
                                      entity.paciente.peso &&
                                      entity.paciente.altura
                                      ">
                                    <td>Sobrepeso</td>
                                    <td>25 - 29,9</td>
                                  </tr>
                                  <tr class="obesidade1" v-if="imc >= 30 &&
                                    imc <= 34.9 &&
                                    entity.paciente.peso &&
                                    entity.paciente.altura
                                    ">
                                    <td>Obesidade grau 1</td>
                                    <td>30 - 34,9</td>
                                  </tr>
                                  <tr class="obesidade2" v-if="imc >= 35 &&
                                    imc <= 39.9 &&
                                    entity.paciente.peso &&
                                    entity.paciente.altura
                                    ">
                                    <td>Obesidade grau 2</td>
                                    <td>35 - 39,9</td>
                                  </tr>
                                  <tr class="obesidade3" v-if="imc >= 40 &&
                                    entity.paciente.peso &&
                                    entity.paciente.altura
                                    ">
                                    <td>Obesidade grau 3</td>
                                    <td>Maior ou igual a 40</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div class="row mt-4">
                              <div class="col-md-4">
                                <label for="">Suporte de O²</label>
                                <select class="form-control" v-model="entity.paciente.suporte_o2" disabled>
                                  <option value="Catete Tipo Óculos">
                                    Catete Tipo Óculos
                                  </option>
                                  <option value="Mascara Reservatório">
                                    Mascara Reservatório
                                  </option>
                                  <option value="V.M">V.M</option>
                                  <option value="HOOD">HOOD</option>
                                  <option value="Cpap Nasal">Cpap Nasal</option>
                                </select>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label for="">Tipos de Acesso Venoso</label>
                                <select class="form-control" v-model="entity.paciente.acesso_venoso" disabled>
                                  <option value="MSD">MSD</option>
                                  <option value="MSE">MSE</option>
                                  <option value="Jugular">Jugular</option>
                                  <option value="Subclávia">Subclávia</option>
                                  <option value="Cefálica">Cefálica</option>
                                  <option value="Outros">Outros</option>
                                </select>
                              </div>
                              <div class="col-md-8" v-if="entity.paciente.acesso_venoso == 'Outros'">
                                <label for="">Qual?</label>
                                <input type="text" class="form-control" v-model="entity.paciente.acesso_venoso_outros"
                                  disabled />
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <toggle-button v-model="entity.paciente.incubadora" color="#15488A" :height="25"
                                  disabled />
                                Transporte em Incubadora?
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-3">
                                <toggle-button v-model="entity.paciente.flg_bomba_infusora" color="#15488A" :height="25"
                                  disabled />
                                Uso de Bomba Infusora?
                              </div>
                              <div class="col-md-4" v-if="entity.paciente.flg_bomba_infusora">
                                <label for="">Quantidade</label>
                                <select class="form-control" v-model="entity.paciente.bomba_infusora" disabled>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                              </div>
                              <div class="col-md-4" v-if="entity.paciente.flg_bomba_infusora">
                                <label for="">Medicamentos</label>
                                <select class="form-control" v-model="entity.paciente.bomba_infusora_medicamentos
                                  " disabled>
                                  <option value="AMIODARONA (ANCORON) 150 MG">
                                    AMIODARONA (ANCORON) 150 MG
                                  </option>
                                  <option value="DOPAMINA 5MG/ML 10ML-AMPOLA
">
                                    DOPAMINA 5MG/ML 10ML-AMPOLA
                                  </option>
                                  <option value="DOBUTAMINA 12,5MG/ML 20ML-AMPOLA">
                                    DOBUTAMINA 12,5MG/ML 20ML-AMPOLA
                                  </option>
                                  <option value="NORAEPINEFRINA 2MG/4ML 4ML-AMPOLA">
                                    NORAEPINEFRINA 2MG/4ML 4ML-AMPOLA
                                  </option>
                                  <option value="FENTANILA 50 mcg/10ml - AMP.">
                                    FENTANILA 50 mcg/10ml - AMP.
                                  </option>
                                  <option value="INSULINA REGULAR">
                                    INSULINA REGULAR
                                  </option>
                                  <option value="MIDAZOLAN 15MG/ML 10ML-AMP">
                                    MIDAZOLAN 15MG/ML 10ML-AMP
                                  </option>
                                  <option value="TRIDIL">TRIDIL</option>
                                </select>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label>Outros Dispositivos</label>
                                <select class="form-control" v-model="entity.paciente.outros_dispositivos" disabled>
                                  <option value="Cateter Vesical">
                                    Cateter Vesical
                                  </option>
                                  <option value="Cateter Nasogástrico">
                                    Cateter Nasogástrico
                                  </option>
                                  <option value="Cateter Umbilical ">
                                    Cateter Umbilical
                                  </option>
                                  <option value="Dreno">Dreno</option>
                                  <option value="Ostomia">Ostomia</option>
                                  <option value="PICC">PICC</option>
                                </select>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-2">
                                <toggle-button v-model="entity.paciente.flg_alergias" color="#15488A" :height="25"
                                  disabled />
                                Alergias
                              </div>
                              <div class="col-md-8" v-if="entity.paciente.flg_alergias">
                                <label for="">Qual?</label>
                                <input type="text" class="form-control" v-model="entity.paciente.alergias" disabled />
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-2">
                                <toggle-button v-model="entity.paciente.covid" color="#15488A" :height="25" disabled />
                                Covid-19
                              </div>
                            </div>
                            <div class="row mt-2" v-if="entity.paciente.covid">
                              <div class="col-md-2">
                                <label for="">Isolamento</label>
                                <div class="form-check">
                                  <input v-model="entity.paciente.covid_isolamento_contato
                                    " class="form-check-input" type="checkbox" value="true" id="Contato" disabled />
                                  <label class="form-check-label" for="Contato" style="font-size: 16px">
                                    Contato
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input v-model="entity.paciente
                                    .covid_isolamento_respiratorio
                                    " class="form-check-input" type="checkbox" value="true" id="Respiratório"
                                    disabled />
                                  <label class="form-check-label" for="Respiratório" style="font-size: 16px">
                                    Respiratório
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!--  <div class="col-md-3">
                                <label for="">Acesso Venoso Periférico</label>
                                <input
                                  type="number"
                                  placeholder="Local"
                                  class="form-control"
                                  v-model="
                                    entity.paciente.acesso_venoso_periferico
                                  "
                                />
                              </div>
                              <div class="col-md-3">
                                <label for="">Acesso Venoso Profundo</label>
                                <input
                                  type="number"
                                  placeholder="Local"
                                  class="form-control"
                                  v-model="
                                    entity.paciente.acesso_venoso_profundo
                                  "
                                />
                              </div>
                              <div class="col-md-3">
                                <label for="">Bomba Infusora</label>
                                <input
                                  type="number"
                                  placeholder="Quantidade"
                                  class="form-control"
                                  v-model="entity.paciente.bomba_infusora"
                                />
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-3">
                                <label for="">Escala de Glasgow</label>
                                <input
                                  data-toggle="modal"
                                  data-target="#glasgowModalCenter"
                                  readonly
                                  type="text"
                                  class="form-control"
                                  id=""
                                  v-model="glasgowCount"
                                />
                              </div>
                              <div class="col-md-9" style="margin-top: 30px">
                                <span v-html="glasgowLabel"></span>
                              </div>
                            </div> -->
                          </div>
                        </div>

                        <div class="row mb-2 mt-3">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">Ficha Clínica</div>
                          </div>
                        </div>
                        <div class="row mt-2 mb-2">
                          <div class="col-md-12">
                            <table class="table table-bordered" style="width: 100%">
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 100%">
                                    Descrição
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="fic in entity.paciente.ficha_clinica" :key="fic.ficha_clinica_id">
                                  <td>
                                    {{ fic.ficha_clinica_id }} -
                                    {{
                                      fic.avaliacao_clinica
                                        .tipo_avaliacao_clinica_nome
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mt-1">
                            <label>Paciente depende de auxilio para se
                              locomover?</label>
                            <select disabled class="form-control" v-model="entity.depende_auxilio">
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div class="col-md-3 mt-1" v-if="entity.depende_auxilio == 'SIM'">
                            <label>Qual?</label>
                            <select disabled class="form-control" v-model="entity.tipo_auxilio_locomocao_id">
                              <option v-for="tipo in tipos_auxilio_locomocao" :key="tipo.tipo_auxilio_locomocao_id"
                                :value="tipo.tipo_auxilio_locomocao_id">
                                {{ tipo.tipo_auxilio_locomocao_nome }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-3 mt-1" v-if="entity.tipo_auxilio_locomocao_id == '6'">
                            <label><span class="required">*</span>
                              Especifique</label>

                            <input disabled type="text" class="form-control"
                              v-model="entity.outro_tipo_auxilio_locomocao" />
                          </div>
                        </div>
                        <div class="row mb-2 mt-3">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">CID's</div>
                          </div>
                        </div>

                        <div class="row" v-if="entity.cids.length > 0">
                          <div class="col-md-12">
                            <table class="table table-bordered" style="width: 100%">
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 100%">
                                    Código e Nome
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="cid in entity.cids" :key="cid.cid_id">
                                  <td>
                                    {{ cid.cid.cid_codigo }} -
                                    {{ cid.cid.cid_nome }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="row" v-else>
                          <div class="col-md-12">Nenhum CID informado</div>
                        </div>
                      </section>
                      <section v-if="entity.tipo_transporte_id != 2">
                        <div class="row mb-2 mt-3">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Avaliações Clínicas do Paciente
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="entity.avaliacoes_clinicas.length > 0">
                          <div class="col-md-12">
                            <table class="table table-bordered" style="width: 100%">
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 52%">
                                    Avaliação
                                  </th>
                                  <th scope="col" style="width: 25%">
                                    Usuário
                                  </th>
                                  <th scope="col" style="width: 23%">
                                    Horário
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="avaliacao in entity.avaliacoes_clinicas" :key="moment(avaliacao.datetime).format('HHmmss')
                                  ">
                                  <td>
                                    {{ avaliacao.avaliacao_clinica_descricao }}
                                  </td>
                                  <td>{{ avaliacao.usuario.usuario_nome }}</td>
                                  <td>
                                    {{
                                      moment(avaliacao.datetime).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="row" v-else>
                          <div class="col-md-12">
                            Nenhuma Avaliação Clínica cadastrada
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="btn btn-edit" v-if="entity.status_solicitacao == 0 || entity.status_solicitacao == 1"
        @click="editar">
        Editar
      </button>
      <button class="btn btn-approve" @click="approve" v-if="entity.status_solicitacao == 0">
        Aprovar
      </button>
      <button class="btn btn-reprove" v-if="entity.status_solicitacao == 0" data-toggle="modal"
        data-target="#modalRejeitar">
        Rejeitar
      </button>
      <button @click="getVeiculosDisponiveis" class="btn btn-info" v-if="entity.status_solicitacao == 2"
        data-toggle="modal" data-target="#modalProgramacao">
        Despachar Novo Veículo
      </button>
      <button class="btn btn-approve" v-if="entity.status_solicitacao == 2" @click="finalizar">
        Finalizar
      </button>
      <button @click="getVeiculosDisponiveis" class="btn btn-approve" data-toggle="modal" data-target="#modalProgramacao"
        v-if="entity.status_solicitacao == 1">
        Programar
      </button>
      <button class="btn btn-reprove" v-if="entity.status_solicitacao == 1 || entity.status_solicitacao == 2"
        data-toggle="modal" data-target="#modalCancelar">
        Cancelar
      </button>
    </div>

    <!-- Modal Programação -->
    <div class="modal fade" id="modalProgramacao" tabindex="-1" role="dialog" aria-labelledby="modalProgramacaoTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Programar Transporte
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-4">
                <label>Data do Procedimento</label>
                <input disabled type="datetime-local" class="form-control" v-model="entity.data_procedimento" />
              </div>
              <div class="col-md-4">
                <label>Tempo estimado de uso do veículo</label>
                <input type="text" v-mask="'99:99'" class="form-control" v-model="programacao.tempouso"
                  v-on:input="getVeiculosDisponiveis" />
              </div>
            </div>
            <div class="row mt-2" v-if="veiculos_disponiveis.length > 0">
              <div class="col-md-12">
                <span>Selecione um veículo</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" v-for="veiculo in veiculos_disponiveis" :key="veiculo.veiculo_id">
                <div class="form-check">
                  <input v-model="veiculo.value" class="form-check-input disabled" type="checkbox" value="true"
                    :id="veiculo.veiculo_id" />
                  <label class="form-check-label" :for="veiculo.veiculo_id" style="font-size: 16px">
                    {{ veiculo.nome_veiculo }} - {{ veiculo.placa_veiculo }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Fechar
            </button>
            <button type="button" class="btn btn-primary" @click="salvarProgramacao">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Lançar Movimento -->
    <div class="modal fade" id="modalLancamento" tabindex="-1" role="dialog" aria-labelledby="modalLancamentoTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Lançar Movimento do Veículo
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-4">
                <label>Posição</label>
                <select class="form-control" v-model="movimentacao.tipo">
                  <option v-for="tpos in tipos_posicao" :key="tpos.id" :value="tpos.posicao_veiculo_id">
                    {{ tpos.posicao_veiculo_nome }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <label>Data/Hora</label>
                <input type="datetime-local" class="form-control" v-model="movimentacao.data" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Fechar
            </button>
            <button type="button" class="btn btn-primary" @click="salvarMovimentacao">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Lista Movimentações -->
    <div class="modal fade" id="modalMovimentacoes" tabindex="-1" role="dialog" aria-labelledby="modalMovimentacoesTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Lista de Movimentações
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Posição</th>
                      <th scope="col">Data da Posição</th>
                      <th scope="col">Usuário</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="posicao in historico" :key="posicao.posicao_veiculo_id"
                      v-bind:class="posicao.status == 0 ? 'deleted' : ''">
                      <th scope="row">
                        {{ posicao.id }}
                      </th>
                      <td>{{ posicao.posicao.posicao_veiculo_nome }}</td>
                      <td>{{ posicao.data_posicao_veiculo }}</td>
                      <td>{{ posicao.usuario.usuario_nome }}</td>

                      <td>
                        <button class="btn btn-crud delete" data-toggle="modal" data-target="#modalHistorico"
                          @click="posicao_selecionada = posicao" v-bind:disabled="posicao.status == 0 ? true : false">
                          <i class="far fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Historico -->
    <div class="modal fade" id="modalHistorico" tabindex="-1" role="dialog" aria-labelledby="modalHistoricoTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Cancelar movimentação
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <label>Justificativa</label>
                <textarea class="form-control" cols="30" rows="10"
                  v-model="posicao_selecionada.justificativa_historico"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Fechar
            </button>
            <button type="button" class="btn btn-primary" @click="deletePosition(id)">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Rejeitar -->
    <div class="modal fade" id="modalRejeitar" tabindex="-1" role="dialog" aria-labelledby="modalRejeitarTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Rejeitar Transporte
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <label>Justificativa</label>
                <textarea class="form-control" cols="30" rows="10" v-model="entity.justificativa_rejeicao"></textarea>
              </div>
              <div class="col-md-12">
                <label>Motivos de Rejeições </label>
                <v-select :options="rejeicoes" :reduce="(rejeicoes) => rejeicoes.id" :label="'rejeicao_nome'"
                  v-model="entity.motivo_rejeicao_id" class="vselect" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Fechar
            </button>
            <button type="button" class="btn btn-primary" @click="rejeitar">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Cancelar -->
    <div class="modal fade" id="modalCancelar" tabindex="-1" role="dialog" aria-labelledby="modalCancelarTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Cancelar Transporte
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <label>Justificativa</label>
                <textarea class="form-control" cols="30" rows="10" v-model="entity.justificativa_cancelamento"></textarea>
              </div>
              <div class="col-md-12">
                <label>Motivos de Cancelamentos </label>
                <v-select :options="cancelamentos" :reduce="(cancelamentos) => cancelamentos.id"
                  :label="'cancelamento_nome'" v-model="entity.cancelamento_id" class="vselect" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Fechar
            </button>
            <button type="button" class="btn btn-primary" @click="cancelar">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";

export default {
  name: "imageUpload",
  data() {
    return {
      configuracoes: {
        judicial: "",
        sinais_vitais: "",
        recorrencia: 0,
        tipo_destino: 0,
      },
      conselho_classe: [
        {
          nome: "CRM",
          id: "1",
        },
        {
          nome: "CRF",
          id: "2",
        },
        {
          nome: "COREN",
          id: "3",
        },
        {
          nome: "CRO",
          id: "4",
        },
        {
          nome: "CRP",
          id: "5",
        },
      ],
      moment: moment,
      isLoading: true,
      fullPage: true,
      page: 1,
      ufs: [],
      cbos: [],
      municipios_destino: [],
      municipios_origem: [],
      tipos_transporte: [],
      tipos_domicilio: [],
      tipos_avaliacao_clinica: [],
      tipos_auxilio_locomocao: [],
      tipos_acompanhante: [],
      tipos_posicao: [],
      motivo_transporte: [],
      veiculos_disponiveis: [],
      unidade_saude: [],
      status: true,

      entity: {
        paciente: {
          endereco: "",
          bairro: "",
          tipo_peso: "KG",
          peso: "",
          altura: "",
        },
        acompanhante: {},
        solicitante: {},
        unidade: {},
        tipo_transporte: {},
        motivo_transporte: {},
        avaliacoes_clinicas: [],
        cids: [],
        profissional: {},
        usuario_aprovacao: {
          usuario_nome: "",
        },
        usario_cancelamento: {

          usuario_nome: ""
        }
      },

      avaliacao_clinica: "",
      cid_selecionado: {},
      programacao: {
        tempouso: "00:00",
      },
      movimentacao: {
        veiculo: {
          posicoes: [],
        },
      },
      cids: [],
      cbos: [],
      veiculos_enviados: [],
      historico: [],
      cancelamentos: [],
      rejeicoes: [],

      configuracoes: {
        judicial: "",
        sinais_vitais: "",
        recorrencia: 0,
        tipo_destino: 0,
      },
      posicao_selecionada: {},
    };
  },
  computed: {
    imc() {
      var imc =
        this.entity.paciente.peso /
        (this.entity.paciente.altura * this.entity.paciente.altura);
      return Math.round(imc);
    },
  },
  methods: {
    deletarVeiculo(obj) {
      const self = this;

      swal({
        title: "Deseja deletar o registro?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O registro não foi excluido!", "info");
            break;

          case "confirm":
            let api = self.$store.state.api + "envio_veiculo/deletar";
            axios
              .post(api, obj)
              .then((response) => {
                swal("Sucesso", "Registro excluído com sucesso!", "success");
                self.getEnvioVeiculo(self.entity.id);
              })
              .catch((error) => {
                swal(
                  "Erro",
                  "Este registro não pode ser deletado, pois já está sendo utilizado no sistema!",
                  "error"
                );
              });
            break;
        }
      });
    },
    editar() {
      const self = this;
      self.$router.push(
        "/solicitacoes/edit/" + self.entity.transporte_sanitario_id
      );
    },
    getConfiguracoes: function () {
      const self = this;
      let api = self.$store.state.api + "configuracoes?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.configuracoes = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    setHistorico(posicoes) {
      this.historico = posicoes;
    },
    getEntity: function (id) {
      const self = this;

      self.$loading(true);
      let api =
        self.$store.state.api +
        "solicitacoes/" +
        id +
        "?with[]=paciente&with[]=solicitante&with[]=unidade&with[]=avaliacoes_clinicas&with[]=cids&with[]=tipo_transporte&with[]=motivo_transporte&with[]=acompanhante&with[]=origem&with[]=destino&with[]=cancelamento&with[]=usuario_aprovacao&with[]=usuario_cancelamento";

      axios
        .get(api)
        .then((response) => {
          self.entity = response.data.data[0];

          if (self.entity.flag_retorna_origem == 0) {
            self.entity.flag_retorna_origem = false;
          } else {
            self.entity.flag_retorna_origem = true;
          }

          self.getEnvioVeiculo(self.entity.transporte_sanitario_id);
          self.getEstadoMunicipioOrigem();
          self.getEstadoMunicipioDestino();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEstadoMunicipioOrigem() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.entity.origem.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios_origem = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEstadoMunicipioDestino() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.entity.destino.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios_destino = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    finalizar() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/finalizar";

      swal({
        title: "Deseja finalizar este transporte?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "A solicitação não foi finalizada!", "info");
            break;

          case "confirm":
            axios
              .post(api, {
                transporte_sanitario_id: self.entity.transporte_sanitario_id,
              })
              .then((response) => {
                swal("Sucesso!", "A solicitação foi finalizada!", "success");
                self.$router.push("/programacoes");
              })
              .catch((error) => {
                self.$message(null, error.response.data, "error");
              });

            break;
        }
      });
    },
    getConfiguracoes: function () {
      const self = this;
      let api = self.$store.state.api + "configuracoes?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.configuracoes = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProfissional: function () {
      const self = this;
      let api = self.$store.state.api + "profissionais?with[]=cbo";

      axios
        .get(api)
        .then((response) => {
          self.profissional = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getCbo: function () {
      const self = this;
      let api = self.$store.state.api + "cbos?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.cbos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    approve() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/aprovacao";

      self.$loading(true);

      axios
        .post(api, {
          transporte_sanitario_id: self.entity.transporte_sanitario_id,
        })
        .then((response) => {
          self.$router.push("/aprovacoes");
          self.$loading(false);
          swal("Sucesso!", "A solicitação foi aprovada!", "success");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    cancelar() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/cancelar";

      self.$loading(true);

      axios
        .post(api, {
          transporte_sanitario_id: self.entity.transporte_sanitario_id,
          justificativa: self.entity.justificativa_cancelamento,
          cancelamento_id: self.entity.cancelamento_id,
        })
        .then((response) => {
          $("#modalCancelar").modal("hide");
          self.$router.push("/solicitacoes");
          self.$loading(false);
          swal("Sucesso!", "A solicitação foi cancelada!", "success");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },

    rejeitar() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/rejeitar";

      self.$loading(true);

      axios
        .post(api, {
          transporte_sanitario_id: self.entity.transporte_sanitario_id,
          justificativa: self.entity.justificativa_rejeicao,
          motivo_rejeicao_id: self.entity.motivo_rejeicao_id,
        })
        .then((response) => {
          $("#modalRejeitar").modal("hide");
          self.$router.push("/solicitacoes");
          self.$loading(false);
          swal("Sucesso!", "A solicitação foi rejeitada!", "success");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    salvarProgramacao() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/programacao";

      self.programacao.transporte_sanitario_id =
        self.entity.transporte_sanitario_id;
      self.programacao.data_procedimento = self.entity.data_procedimento;
      self.programacao.veiculos_disponiveis = self.veiculos_disponiveis;

      axios
        .post(api, self.programacao)
        .then((response) => {
          self.getEntity(self.entity.id);
          self.getEnvioVeiculo(self.entity.id);

          $("#modalProgramacao").modal("hide");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    salvarMovimentacao() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/movimentar_veiculo";

      self.$loading(true);

      axios
        .post(api, self.movimentacao)
        .then((response) => {
          self.getEnvioVeiculo(self.entity.transporte_sanitario_id);
          $("#modalLancamento").modal("hide");
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getTiposAcompanhante: function (id) {
      const self = this;
      let api = self.$store.state.api + "tipo_acompanhante?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.tipos_acompanhante = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEnvioVeiculo: function (id) {
      const self = this;
      let api =
        self.$store.state.api +
        "envio_veiculo?transporte_sanitario_id=" +
        id +
        "&with[]=veiculo&with[]=posicoes";

      axios
        .get(api)
        .then((response) => {
          self.veiculos_enviados = response.data.data;
          self.$loading(false);
          self.isLoading = false;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
          self.isLoading = false;
        });
    },
    deletePosition: function () {
      const self = this;

      let api =
        self.$store.state.api +
        "movimentacao_veiculo/" +
        self.posicao_selecionada.id;

      self.$loading(true);
      axios
        .patch(api, {
          status: 0,
          justificativa_historico:
            self.posicao_selecionada.justificativa_historico,
        })
        .then((response) => {
          self.getEnvioVeiculo(self.entity.transporte_sanitario_id);
          self.$loading(false);
          self.isLoading = false;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
          self.isLoading = false;
        });
    },

    selectUnidadeDestino() {
      const self = this;

      if (self.entity.destino.municipio_id == null) {
        return;
      }

      self.entity.destino.cep = self.entity.destino.unidade_saude_id.cep;
      self.entity.destino.endereco =
        self.entity.destino.unidade_saude_id.endereco;
      self.entity.destino.numero = self.entity.destino.unidade_saude_id.numero;
      self.entity.destino.ponto_refencia =
        self.entity.destino.unidade_saude_id.ponto_refencia;
      // self.entity.destino.complemento =
      //   self.entity.destino.unidade_saude_id.complemento;
      self.entity.destino.bairro = self.entity.destino.unidade_saude_id.bairro;
      self.entity.destino.estabelecimento_nome =
        self.entity.destino.unidade_saude_id.unidade_saude_nome;

      let api =
        self.$store.state.api +
        "municipios?municipio_id=" +
        self.entity.destino.unidade_saude_id.municipio_id;

      axios
        .get(api)
        .then((response) => {
          self.entity.destino.estado_id = response.data.data[0].uf_id;
          self.getEstadoMunicipioDestino();
          self.entity.destino.municipio_id = response.data.data[0].municipio_id;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTiposAuxilioLocomocao: function (id) {
      const self = this;
      let api =
        self.$store.state.api + "tipo_auxilio_locomocao?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.tipos_auxilio_locomocao = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCancelamentos: function (id) {
      const self = this;
      let api = self.$store.state.api + "cancelamentos?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.cancelamentos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getRejeicoes: function (id) {
      const self = this;
      let api = self.$store.state.api + "rejeicoes?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.rejeicoes = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTiposPosicao: function () {
      const self = this;
      let api =
        self.$store.state.api + "posicao_veiculo?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.tipos_posicao = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUFs: function () {
      const self = this;
      let api =
        self.$store.state.api + "ufs?orderByAsc=uf_nome&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.ufs = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getVeiculosDisponiveis() {
      const self = this;
      let api = self.$store.state.api + "veiculos_disponiveis";

      if (
        self.programacao.tempouso.includes("_") ||
        self.programacao.tempouso == ""
      ) {
        return;
      }

      self.$loading(true);

      self.programacao.transporte_sanitario_id =
        self.entity.transporte_sanitario_id;
      self.programacao.data_procedimento = self.entity.data_procedimento;

      axios
        .post(api, self.programacao)
        .then((response) => {
          self.veiculos_disponiveis = response.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getTiposDomicilio: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_domicilio";

      axios
        .get(api)
        .then((response) => {
          self.tipos_domicilio = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    self.getConfiguracoes();
    self.getProfissional();
    let id = self.$route.params.id;
    if (id) {
      self.change_password = false;
      self.getConfiguracoes();
      self.getTiposAcompanhante();
      self.getTiposAuxilioLocomocao();
      self.getTiposDomicilio();
      self.getTiposPosicao();
      self.getUFs();
      self.getCancelamentos();
      self.getRejeicoes();
      self.getEntity(id);
    }
  },
  components: {
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}

.b-card-subtitle {
  font-size: 18px;
  font-weight: bold;
}

.btn-edit {
  position: fixed;
  width: 10%;
  /* height: 25px; */
  bottom: 40px;
  right: 60%;
  background-color: #3387f4;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  box-shadow: 5px 5px 7px #888;
}

.btn-edit:hover {
  background-color: #2781f7;
  color: #fff;
  box-shadow: 5px 5px 7px #888;
}

.btn-info {
  position: fixed;
  width: 15%;
  /* height: 25px; */
  bottom: 40px;
  right: 60%;
  background-color: #5f9ef1;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  box-shadow: 5px 5px 7px #6a6a6a;
}

.btn-approve {
  position: fixed;
  width: 10%;
  /* height: 25px; */
  bottom: 40px;
  right: 45%;
  background-color: #25d366;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  box-shadow: 5px 5px 7px #888;
}

.btn-approve:hover {
  background-color: #0daa47;
  color: #fff;
}

.btn-reprove {
  position: fixed;
  width: 10%;
  /* height: 25px; */
  bottom: 40px;
  right: 30%;
  background-color: #d3252e;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  box-shadow: 5px 5px 7px #888;
}

.btn-reprove:hover {
  background-color: #af151d;
  color: #fff;
}

/* progress2 Tracker v2 */

ol.progress2[data-steps="2"] li {
  width: 49% !important;
  z-index: 99;
}

ol.progress2[data-steps="3"] li {
  width: 33% !important;
  z-index: 99;
}

ol.progress2[data-steps="4"] li {
  width: 24% !important;
  z-index: 99;
}

ol.progress2[data-steps="5"] li {
  width: 19% !important;
  z-index: 99;
}

ol.progress2[data-steps="6"] li {
  width: 16% !important;
  z-index: 99;
}

ol.progress2[data-steps="7"] li {
  width: 14% !important;
  z-index: 99;
}

ol.progress2[data-steps="8"] li {
  width: 12% !important;
  z-index: 99;
}

ol.progress2[data-steps="9"] li {
  width: 11% !important;
  z-index: 99;
}

.progress2 {
  width: 100% !important;
  list-style: none !important;
  list-style-image: none !important;
  margin: 20px 0 20px 0 !important;
  padding: 0 !important;
  z-index: 99;
}

.progress2 li {
  float: left !important;
  text-align: center !important;
  position: relative !important;
  z-index: 99;
}

.progress2 .name {
  display: block !important;
  vertical-align: bottom !important;
  text-align: center !important;
  margin-bottom: 1em !important;
  color: black !important;
  opacity: 0.3 !important;
  z-index: 99;
}

.progress2 .step {
  color: black !important;
  border: 3px solid silver !important;
  background-color: silver !important;
  border-radius: 50% !important;
  line-height: 1.5 !important;
  width: 2em !important;
  height: 2em !important;
  display: inline-block !important;
  z-index: 0 !important;
  font-weight: bold;
}

.progress2 .step-description {
  position: absolute;
  top: 80px;
  left: 30%;
}

.progress2 .step span {
  opacity: 0.3 !important;
}

.progress2 .active .name,
.progress2 .active .step span {
  opacity: 1 !important;
}

.progress2 .step:before {
  content: "" !important;
  display: block !important;
  background-color: silver !important;
  height: 0.4em !important;
  width: 50% !important;
  position: absolute !important;
  bottom: 0.6em !important;
  left: 0 !important;
  z-index: -1 !important;
}

.progress2 .step:after {
  content: "" !important;
  display: block !important;
  background-color: silver !important;
  height: 0.4em !important;
  width: 50% !important;
  position: absolute !important;
  bottom: 0.6em !important;
  right: 0 !important;
  z-index: -1 !important;
}

.progress2 li:first-of-type .step:before {
  display: none !important;
}

.progress2 li:last-of-type .step:after {
  display: none !important;
}

.progress2 .done .step,
.progress2 .done .step:before,
.progress2 .done .step:after,
.progress2 .active .step,
.progress2 .active .step:before {
  background-color: yellowgreen !important;
}

.progress2 .done .step,
.progress2 .active .step {
  border: 3px solid yellowgreen !important;
}

.deleted {
  text-decoration: line-through;
  color: #a1a1a1;
}

tr.obesidade1 {
  color: #fff;
  font-weight: bold;
  background-color: #ff4500;
}

tr.obesidade2 {
  color: #fff;
  font-weight: bold;
  background-color: #ff0000;
}

tr.obesidade3 {
  color: #fff;
  font-weight: bold;
  background-color: #e30505;
}
</style>
