<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'transportesCrud'"
          crudName="Solicitações"
          newText="Nova Solicitação"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="true"
          :enableDelete="false"
          :enableView="false"
          :enableReset="false"
          :dataForm="dataForm"
          :customFilters="customFilters"
          :extra="extra"
          @view="view"
          @duplicate="duplicate"
          @filtered_ds="filtered_ds"
          @filtered_dp="filtered_dp"
        >
        </base-crud>
      </div>
    </div>
    <!-- Modal Duplicar -->
    <div
      class="modal fade"
      id="modalDuplicateCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalDuplicateCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalDuplicateCenterTitle">
              Duplicar Evento
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <label>Data do Transporte</label>
                <input
                  v-model="duplicate_data.data_procedimento"
                  type="datetime-local"
                  class="form-control"
                />
              </div>
              <div class="col-md-12 mt-1">
                <label>Motivo do Transporte</label>
                <v-select
                  :options="motivo_transporte"
                  label="motivo_transporte_nome"
                  v-model="duplicate_data.motivo_transporte_id"
                  item-text="motivo_transporte_nome"
                >
                  <div slot="no-options">
                    Aguarde, carregando lista de Motivos
                  </div>
                </v-select>
              </div>
              <div class="col-md-12 mt-1">
                <label>Observações do Transporte</label>
                <textarea
                  class="form-control"
                  cols="30"
                  rows="3"
                  v-model="duplicate_data.observacao_transporte"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="saveDuplicate"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      duplicate_data: {
        id: null,
      },
      motivo_transporte: [],
      customFilters: [
        {
          name: "data_solicitacao_f",
          type: "calendar",
          call: "filtered_ds",
        },
        {
          name: "data_procedimento_f",
          type: "calendar",
          call: "filtered_dp",
        },
      ],
      data_solicitacao: "",
      table: "solicitacoesTable",
      showMessageImport: false,
      usersCrud: {},
      pages: [],
      url: "",
      columns: [
        "numero_solicitacao",
        "data_solicitacao_f",
        "data_procedimento_f",
        "tipo_transporte_id",
        "paciente_id",
        "status_solicitacao",
        "actions",
      ],
      tableData: [
        "numero_solicitacao",
        "data_solicitacao",
        "data_procedimento",
        "unidade_saude_id",
        "tipo_transporte_id",
        "paciente_id",
        "status_solicitacao",
      ],
      options: {
        filterByColumn: true,
        debounce: 1000,
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        sortable: [
          "numero_solicitacao",
          "data_solicitacao",
          "data_procedimento",
          "tipo_transporte_id",
          "paciente_id",
          "status_solicitacao",
        ],
        filterable: [
          "numero_solicitacao",
          "paciente_id",
          "tipo_transporte_id",
          "status_solicitacao",
        ],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          numero_solicitacao: "Número Solicitação",
          data_solicitacao_f: "Data da Solicitação",
          data_procedimento_f: "Data do Transporte",
          unidade_saude_id: "Unidade solicitante",
          tipo_transporte_id: "Tipo de transporte",
          paciente_id: "Nome Paciente",
          status_solicitacao: "Status",
          actions: "Ações",
        },

        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {
          status_solicitacao: [
            {
              id: 0,
              text: "Solicitado",
            },
            {
              id: 1,
              text: "Aprovado",
            },
            {
              id: 2,
              text: "Programado",
            },
            {
              id: 3,
              text: "Finalizado",
            },
            {
              id: 4,
              text: "Cancelado",
            },
          ],
        },
        templates: {
          tipo_transporte_id: function (h, row, index) {
            return row.tipo_transporte.tipo_transporte_nome;
          },
          paciente_id: function (h, row, index) {
            return row.paciente ? row.paciente.nome_paciente : "NÃO SE APLICA";
          },

          // profissional_id: function (h, row, index) {
          //   return row.profissional.profissional_nome;
          // },
          status_solicitacao: function (h, row, index) {
            if (row.status_solicitacao == 0) {
              return (
                <h5>
                  <span class="badge badge-warning">Solicitado</span>
                </h5>
              );
            }
            if (row.status_solicitacao == 1) {
              return (
                <h5>
                  <span class="badge badge-info">Aprovado</span>
                </h5>
              );
            }
            if (row.status_solicitacao == 2) {
              return (
                <h5>
                  <span class="badge badge-primary">Programado</span>
                </h5>
              );
            }
            if (row.status_solicitacao == 3) {
              return (
                <h5>
                  <span class="badge badge-success">Finalizado</span>
                </h5>
              );
            }
            if (row.status_solicitacao == 4) {
              return (
                <h5>
                  <span class="badge badge-danger">
                    Cancelado
                    <p class="mt-1">
                      Motivo:{" "}
                      {row.cancelamento
                        ? row.cancelamento.cancelamento_nome
                        : "Não informado"}
                    </p>
                  </span>
                </h5>
              );
            }
          },
          // cancelamento_id: function (h, row, index) {
          //   return row.cancelamento.cancelamento_nome;
          // },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;

          if (
            this.$parent.$parent.$parent.data_procedimento != null &&
            this.$parent.$parent.$parent.data_procedimento != ""
          ) {
            requestData.params.data_procedimento =
              this.$parent.$parent.$parent.data_procedimento;
          }

          if (
            this.$parent.$parent.$parent.data_solicitacao != null &&
            this.$parent.$parent.$parent.data_solicitacao != ""
          ) {
            requestData.params.data_solicitacao =
              this.$parent.$parent.$parent.data_solicitacao;
          }

          requestData.params.query = "";
          requestData.params.with = [
            "paciente",
            // "profissional",
            "tipo_transporte",
            "solicitante",
            "cancelamento",
          ];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      extra: [
        {
          id: 1,
          label: "",
          class: "ml-1 btn btn-crud view",
          title: "Visualizar",
          name: "view",
          icon: "fas fa-eye",
        },
        {
          id: 2,
          label: "",
          class: "ml-1 btn btn-crud check mt-1",
          title: "Duplicar",
          name: "duplicate",
          icon: "fas fa-clone",
        },
      ],
      endPoint: "solicitacoes",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    filtered_dp(date) {
      this.data_procedimento = date;
    },
    filtered_ds(date) {
      this.data_solicitacao = date;
    },
    view(props, row, index) {
      const self = this;
      this.$router.push("/aprovacoes/aprovacao/" + props.row.id);
    },
    duplicate(props, row, index) {
      const self = this;

      console.log(props);

      self.duplicate_data.id = props.row.transporte_sanitario_id;

      $("#modalDuplicateCenter").modal("show");
    },
    saveDuplicate() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/duplicate";

      axios
        .post(api, self.duplicate_data)
        .then((response) => {
          $("#modalDuplicateCenter").modal("hide");
          self.$message("Sucesso", "Solicitação Duplicada", "success");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },

    getMotivos() {
      const self = this;
      let api = self.$store.state.api + "get_motivos_por_tipo";

      axios
        .post(api, { tipo_transporte: 1 })
        .then((response) => {
          self.motivo_transporte = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    query: function (query) {
      let columns = {
        numero_solicitacao: "numero_solicitacao",
        data_solicitacao: "data_solicitacao",
        tipo_transporte_id: "tipo_transporte[tipo_transporte_nome]",
        paciente_id: "paciente[nome_paciente]",
        status_solicitacao: "status_solicitacao",
        cancelamento_id: "cancelamento[cancelamento_nome]",
      };
      let filters = "orderByDesc=data_solicitacao";
      $.each(query, function (index, value, row) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    openInput() {
      document.getElementById("fileImport").click();
    },
  },
  mounted() {
    this.getMotivos();
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>