<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end align-items-center">
        <span class="mr-1" style="color: #8800aa;">
          <span class="badge" style="background: #8800aa; border-radius: 100px;">
            R
          </span>
          Atendimento recorrente
        </span>
        <span class="mr-1" style="color: #aa5588;">
          <span class="badge" style="background: #aa5588; border-radius: 100px;">
            1º
          </span>
          Primeira recorrencia
        </span>
        <span>
          <toggle-button @change="showFinisheds" :labels="{ checked: 'Sim', unchecked: 'Não' }" :width="55"
            v-model="showFinished" />
          <span>
            Exibir finalizados
          </span>
        </span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <base-crud :ref="'programacaoCrud'" crudName="Cancelados" newText="Nova Solicitação" :table="table"
          :columns="columns" :options="options" :endPoint="endPoint" :enableAdd="false" :enableEdit="false"
          :enableDelete="false" :enableView="false" :enableReset="false" :dataForm="dataForm" :extra="extra"
          @approving="approving" :customFilters="customFilters" @filtered_ds="filtered_ds" @filtered_dp="filtered_dp">
        </base-crud>

      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      customFilters: [
        {
          name: "data_solicitacao_f",
          type: "calendar",
          call: "filtered_ds",
        },
        {
          name: "data_procedimento_f",
          type: "calendar",
          call: "filtered_dp",
        },
      ],
      data_solicitacao: "",
      data_procedimento: "",
      cancelamentos: [],
      table: "programacaoTable",
      showFinished: false,
      usersCrud: {},
      pages: [],
      url: "",
      columns: [
        "numero_solicitacao",
        "data_solicitacao_f",
        "data_procedimento_f",
        "tipo_transporte_id",
        "paciente_id",
        "status_solicitacao",
        "cancelamento_id"
      ],
      tableData: [
        "numero_solicitacao",
        "data_solicitacao",
        "data_procedimento",
        "tipo_transporte_id",
        "paciente_id",
        "status_solicitacao",
        "cancelamento_id"
      ],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: [
          "numero_solicitacao",
          "paciente_id",
          "profissional_id",
          "status_solicitacao",
          "tipo_transporte_id",
          "cancelamento_id",
        ],
        sortable: [
          "numero_solicitacao",
          "data_solicitacao",
          "data_procedimento",
          "tipo_transporte_id",
          "paciente_id",
          "status_solicitacao",
          "cancelamento_id"
        ],
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          numero_solicitacao: "Número Solicitacao",
          data_solicitacao_f: "Data da Solicitação",
          data_procedimento_f: "Data do Transporte",
          unidade_saude_id: "Unidade solicitante",
          tipo_transporte_id: "Tipo de transporte",
          paciente_id: "Nome Paciente",
          status_solicitacao: "Status",
          cancelamento_id: "Motivo do cancelamento"
        },

        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {
          cancelamento_id: [],
        },
        templates: {
          tipo_transporte_id: function (h, row, index) {
            return row.tipo_transporte.tipo_transporte_nome;
          },

          cancelamento_id: function (h, row, index) {
            return row.cancelamento ? row.cancelamento.cancelamento_nome : "Não informado";
          },
          paciente_id: function (h, row, index) {
            if (row.flag_recorrencia && row.duplicado_recorrencia) {
              var paciente = <h5 style="color: #8800aa;">
                {row.paciente.nome_paciente}&nbsp;<span class="badge" style="background: #8800aa; border-radius: 100px;">
                  R
                </span>
              </h5>
            } else if (row.flag_recorrencia && !row.duplicado_recorrencia) {
              var paciente = <h5 style="color: #aa5588;">
                {row.paciente.nome_paciente}&nbsp;<span class="badge" style="background: #aa5588; border-radius: 100px;">
                  1º
                </span>
              </h5>
            } else {
              var paciente = row.paciente.nome_paciente;
            }

            return (row.paciente) ? paciente : "NÃO SE APLICA";
          },
          profissional_id: function (h, row, index) {
            return row.profissional.profissional_nome;
          },
          status_solicitacao: function (h, row, index) {
            if (row.status_solicitacao == 4) {
              return (
                <h5>
                  <span class="badge badge-danger">Cancelado</span>
                </h5>
              );
            }
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;

          if (
            this.$parent.$parent.$parent.data_procedimento != null &&
            this.$parent.$parent.$parent.data_procedimento != ""
          ) {
            requestData.params.data_procedimento =
              this.$parent.$parent.$parent.data_procedimento;
          }

          if (
            this.$parent.$parent.$parent.data_solicitacao != null &&
            this.$parent.$parent.$parent.data_solicitacao != ""
          ) {
            requestData.params.data_solicitacao =
              this.$parent.$parent.$parent.data_solicitacao;
          }

          requestData.params.query = "";
          requestData.params.with = [
            "paciente",
            "profissional",
            "tipo_transporte",
            "solicitante",
            "cancelamento"
          ];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      extra: [
        {
          id: 1,
          label: "",
          class: "btn btn-crud edit",
          title: "Aprovar",
          name: "approving",
          icon: "fas fa-check",
        },
      ],
      endPoint: "solicitacoes",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    filtered_dp(date) {
      this.data_procedimento = date;
    },
    filtered_ds(date) {
      this.data_solicitacao = date;
    },
    showFinisheds(res) {
      const self = this;
      self.$refs.programacaoCrud.$refs.table.refresh();
    },
    approving(props, row, index) {
      const self = this;
      this.$router.push("/programacoes/programacao/" + props.row.id);
    },
    query: function (query) {
      let columns = {
        numero_solicitacao: "numero_solicitacao",
        paciente_id: "paciente[nome_paciente]",
        profissional_id: "profissional[profissional_nome]",
        data_solicitacao: "data_solicitacao",
        tipo_transporte_id: "tipo_transporte[tipo_transporte_nome]",
        data_procedimento: "data_procedimento",
        status_solicitacao: "status_solicitacao",
        cancelamento_id: "cancelamento_id"
      };
      if (this.showFinished != true) {
        var filters = "orderByDesc=data_procedimento&status_solicitacao=4&";
      } else {
        var filters = "orderByDesc=data_procedimento&";
      }
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    openInput() {
      document.getElementById("fileImport").click();
    },

    getCancelamentos: function () {
      const self = this;
      let api = self.$store.state.api + "cancelamentos?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.cancelamentos = response.data.data;

          self.cancelamentos.forEach(element => {
            var obj = {
              id: element.id,
              text: element.cancelamento_nome
            }
            self.options.listColumns.cancelamento_id.push(obj);
          });
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },

  mounted() {

    this.getCancelamentos()
  }
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>