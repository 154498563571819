import Crud from '../views/Transporte/Cancelado/Table'


let routes = {
    path: '/cancelados',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [

        {
            path: '/cancelados',
            name: 'list-cancelados',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Cancelados',
                breadcrumb: [
                    {
                        text: 'Cancelados',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
