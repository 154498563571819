import Crud from '../views/Transporte/Solicitacao/Table'
import Entity from '../views/Transporte/Solicitacao/Entity'
import Aprovacoes from '../views/Transporte/Aprovacao/Table'
import Aprovacao from '../views/Transporte/Programacao/Entity'

let routes = {
    path: '/solicitacoes',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/solicitacoes/add',
            name: 'NovoTransporte',
            component: Entity
        },
        {
            path: '/solicitacoes/edit/:id?',
            name: 'EditarTransporte',
            component: Entity
        },
        {
            path: '/solicitacoes',
            name: 'list-transporte',
            component: Crud,
            meta: {
                pageTitle: 'Solicitações de transporte',
                breadcrumb: [
                    {
                        text: 'Pesquisa',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/aprovacoes',
            name: 'list-aprovacoes',
            component: Aprovacoes,
            meta: {
                pageTitle: 'Pendentes de Aprovação',
                breadcrumb: [
                    {
                        text: 'Aprovações',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/aprovacoes/aprovacao/:id?',
            name: 'AprovarSolicitacao',
            component: Aprovacao
        },
    ]
};


export default routes
