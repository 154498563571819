<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'usersCrud'"
          crudName="Pacientes"
          newText="Novo"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="true"
          :enableDelete="true"
          :enableView="false"
          :enableReset="false"
          :dataForm="dataForm"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      table: "pacienteTable",
      showMessageImport: false,
      usersCrud: {},
      pages: [],
      url: "",
      columns: ["paciente_id", "nome_paciente", "nome_mae", "actions"],
      tableData: [
        "paciente_id",
        "nome_paciente",
        "nome_mae",
        "idade",
        "data_nascimento",
        "sexo",
        "raca",
        "altura",
        "peso",
        "endereco",
        "bairro",
        "telefone",
        "cpf",
        "data_registro",
        "nome_social",
        "nome_pai",
        "tipo_idade",
        "tipo_peso",
        "cns",
        "numero",
        "complemento",
        "cep",
        "ponto_referencia",
        "tipo_domicilio_id",
        "municipio_id",
        "data_gravacao",
        "status",
        "outro_tipo_domicilio",
        "dificuldade_acesso_domicilio_descricao",
        "dificuldade_acesso_paciente_descricao",
      ],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["paciente_id", "nome_paciente"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          paciente_id: "ID",
          nome_paciente: "Nome do Paciente",
          nome_mae: "Nome da Mãe",
          idade: "Idade",
          data_nascimento: "Data de Nascimento",
          sexo: "Sexo",
          raca: "Raça",
          altura: "Altura",
          peso: "Peso",
          endereco: "Endereço",
          bairro: "Bairro",
          telefone: "Telefone",
          cpf: "CPF",
          data_registro: "Data de Registro",
          nome_social: "Nome Social",
          nome_pai: "Nome do Pai",
          tipo_idade: "Tipo de Idade",
          tipo_peso: "Tipo de Peso",
          cns: "CNS",
          numero: "Número",
          complemento: "Complemento",
          cep: "CEP",
          ponto_referencia: "Ponto de Referência",
          tipo_domicilio_id: "Tipo de Endereço",
          municipio_id: "Municipio",
          data_gravacao: "Data de Gravação",
          status: "Status",
          outro_tipo_domicilio: "Outro Tipo de Domicilio",
          dificuldade_acesso_domicilio_descricao:
            "Descrição de Dificuldade de Acesso de Domicilio",
          dificuldade_acesso_paciente_descricao:
            "Descrição de Dificuldade de Acesso do Paciente",
          actions: "Ações",
        },
        sortable: ["paciente_id", "nome_paciente", "nome_mae", "status"],
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {
          status: [
            {
              id: 1,
              text: "Ativo",
            },
            {
              id: 0,
              text: "Inativo",
            },
          ],
        },
        templates: {},
        requestFunction: function (data) {
          let requestData = {};

          console.log(this.$parent.$parent);

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ["company"];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "paciente",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },
    query: function (query) {
      let columns = {
        paciente_id: "paciente_id",
        nome_paciente: "nome_paciente",
        nome_mae: "nome_mae",
      };
      let filters = "orderByAsc=nome_paciente&";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>