<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Estabalecimento</div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> Nome:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        id="Name"
                        class="form-control"
                        v-model="estabelecimentos.estabelecimento_nome"
                        placeholder=""
                      />
                    </div>

                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> Descrição:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        id="Name"
                        class="form-control"
                        v-model="estabelecimentos.descricao_estabelecimento"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> CEP:
                      </label>
                      <input
                        autocomplete="new-password"
                        id="Name"
                        v-model="estabelecimentos.cep"
                        placeholder=""
                        type="tel"
                        class="form-control"
                        v-on:keyup="buscar"
                        v-mask="'99999-999'"
                      />
                    </div>

                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> Endereço:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        id="Name"
                        class="form-control"
                        v-model="estabelecimentos.endereco"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> Bairro:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        id="Name"
                        class="form-control"
                        v-model="estabelecimentos.bairro"
                        placeholder=""
                      />
                    </div>

                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> Complemento:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        id="Name"
                        class="form-control"
                        v-model="estabelecimentos.complemento"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> Ponto de Referência:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        id="Name"
                        class="form-control"
                        v-model="estabelecimentos.ponto_referencia"
                        placeholder=""
                      />
                    </div>

                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> Número:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        id="inputNumero"
                        class="form-control"
                        v-model="estabelecimentos.numero"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> CNES:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        id="Name"
                        class="form-control"
                        v-model="estabelecimentos.cnes"
                        placeholder=""
                      />
                    </div>

                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> Situação do
                        Estabalecimento:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        id="Name"
                        class="form-control"
                        v-model="estabelecimentos.situacao_estabelecimento"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> Telefone:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        id="Name"
                        class="form-control"
                        v-model="estabelecimentos.telefone"
                        placeholder=""
                      />
                    </div>
                    <div class="col-md-2">
                      <label><span class="required">*</span> Estado</label>
                      <v-select
                        :options="ufs"
                        :reduce="(ufs) => ufs.id"
                        :label="'uf_nome'"
                        v-on:input="getEstadoMunicipioOrigem"
                        v-model="estabelecimentos.estado_id"
                        class="vselect"
                      />
                    </div>
                    <div class="col-md-4">
                      <label><span class="required">*</span> Município</label>
                      <v-select
                        :options="municipios_origem"
                        :reduce="(municipios) => municipios.id"
                        :label="'municipio_nome'"
                        v-model="estabelecimentos.municipio_id"
                        class="vselect"
                      />
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> Tipo do Estabalecimento:
                      </label>
                      <select
                        class="form-control"
                        v-model="estabelecimentos.tipo_estabelecimento_id"
                      >
                        <option
                          v-for="tipo_estabelecimento in tipo_estabelecimentos"
                          :key="tipo_estabelecimento.tipo_estabelecimento_id"
                          :value="tipo_estabelecimento.tipo_estabelecimento_id"
                        >
                          {{ tipo_estabelecimento.tipo_estabelecimento_nome }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <label
                        ><span class="required">*</span> Unidade de
                        Saúde:</label
                      >
                      <v-select
                        :options="unidades_saude"
                        :reduce="(unidades_saude) => unidades_saude.id"
                        :label="'unidade_saude_nome'"
                        v-model="estabelecimentos.unidade_saude_id"
                        class="vselect"
                      />
                    </div>
                  </div>

                  <br />
                  <span class="required_fields">
                    <span class="required">*</span>
                    <strong>Campos obrigatórios</strong>
                  </span>
                  <div class="block text-right">
                    <router-link
                      to="/estabelecimento"
                      class="btn btn-back mr-1"
                    >
                      <i class="fa fa-arrow-left"></i> Voltar
                    </router-link>
                    <a class="btn btn-add" @click="save">
                      Salvar
                      <i class="fa fa-save"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  data() {
    return {
      estabelecimentos: {
        estabelecimento_nome: "",
        descricao_estabelecimento: "",
        endereco: "",
        bairro: "",
        complemento: "",
        cep: "",
        ponto_referencia: "",
        telefone: "",
        cnes: "",
        situacao_estabelecimento: "",
        numero: "",
        municipio_id: "",
        tipo_estabelecimento_id: "",
        estado_id: "",
        unidade_saude_id: "",
      },
      unidades_saude: [],
      municipios: [],
      tipo_estabelecimentos: [],
      ufs: [],
      municipios_origem: [],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "estabelecimento";

      if (self.estabelecimentos.estabelecimento_id) {
        self.estabelecimentos._method = "PUT";
        api += "/" + self.estabelecimentos.estabelecimento_id;
      }

      axios
        .post(api, self.estabelecimentos)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/estabelecimento");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEstabelecimento: function (id) {
      const self = this;
      const api = self.$store.state.api + "estabelecimento/" + id;

      axios
        .get(api)
        .then((response) => {
          self.estabelecimentos = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUnidadeSaude: function () {
      const self = this;
      let api =
        self.$store.state.api + "unidade_saude?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.unidades_saude = response.data.data;
          console.log(response.data.data);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getMunicipios: function () {
      const self = this;
      let api = self.$store.state.api + "municipios?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.municipios = response.data.data;
          console.log(response.data.data);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTipoEstabelecimento: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_estabelecimento?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.tipo_estabelecimentos = response.data.data;
          console.log(response.data.data);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEstadoMunicipioOrigem() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.estabelecimentos.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios_origem = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUFs: function () {
      const self = this;
      let api =
        self.$store.state.api + "ufs?orderByAsc=uf_nome&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.ufs = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    buscar: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(self.estabelecimentos.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + self.estabelecimentos.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumero").focus();
              self.naoLocalizado = true;
              return;
            }

            self.estabelecimentos.endereco = endereco.logradouro;
            self.estabelecimentos.bairro = endereco.bairro;
            self.estabelecimentos.complemento = endereco.complemento;

            $("#inputNumero").focus();
          }
        );
      }
    },
  },
  mounted: function () {
    const self = this;

    self.getMunicipios();
    self.getUnidadeSaude();
    self.getEstadoMunicipioOrigem();
    self.getUFs();
    self.getTipoEstabelecimento();

    let id = self.$route.params.id;
    if (id) {
      self.getEstabelecimento(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>