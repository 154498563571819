<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Motivo de Transporte</div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> Nome do Motivo do
                        Transporte:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        id="Name"
                        class="form-control"
                        v-model="motivos.motivo_transporte_nome"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div class="custom-control custom-switch">
                    <input
                      autocomplete="new-password"
                      v-model="motivos.flag_procedimento"
                      placeholder=""
                      type="checkbox"
                      class="custom-control-input"
                      id="customSwitch1"
                    />
                    <label class="custom-control-label" for="customSwitch1"
                      >Procedimento</label
                    >
                  </div>

                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="Name">
                        <span class="required">*</span> Status:
                      </label>
                      <input
                        autocomplete="new-password"
                        type="text"
                        id="Name"
                        class="form-control"
                        v-model="motivos.status"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-md-12">
                      <div class="b-card-subtitle">
                        Tipos de Motivos de Transporte
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <v-select
                        :options="tipo_transportes"
                        label="tipo_transporte_nome"
                        v-model="tipo_transporte_selecionado"
                      ></v-select>
                    </div>
                    <div class="col-md-12 mt-1 text-right">
                      <button class="btn btn-primary" @click="incluirTipo">
                        <i class="fas fa-plus"></i> Incluir Tipo
                      </button>
                    </div>
                  </div>

                  <div
                    class="row mt-4"
                    v-if="motivos.tipo_transportes.length > 0"
                  >
                    <div class="col-md-12">
                      <table class="table table-bordered" style="width: 100%">
                        <thead>
                          <tr>
                            <th scope="col" style="width: 100%">Nome</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="tipo_transporte in motivos.tipo_transportes"
                            :key="tipo_transporte.tipo_transporte_id"
                          >
                            <td>
                              {{ tipo_transporte.tipo_transporte_nome }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                  <div
                    class="row mt-4"
                    v-if="motivos.tipo_motivo_transportes.length > 0"
                  >
                    <div class="col-md-12">
                      <div class="b-card-subtitle">
                        <b>Tipos de Motivos de Transporte Vinculados:</b>
                      </div>
                      <table class="table table-bordered" style="width: 100%">
                        <thead>
                          <tr>
                            <th scope="col" style="width: 100%">Nome</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="tipo_motivo_transporte in motivos.tipo_motivo_transportes"
                            :key="tipo_motivo_transporte.tipo_transporte_id"
                          >
                            <td>
                              {{
                                tipo_motivo_transporte.tipo_transporte[0]
                                  .tipo_transporte_nome
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br />
                  <span class="required_fields">
                    <span class="required">*</span>
                    <strong>Campos obrigatórios</strong>
                  </span>
                  <div class="block text-right">
                    <router-link
                      to="/motivo_transporte"
                      class="btn btn-back mr-1"
                    >
                      <i class="fa fa-arrow-left"></i> Voltar
                    </router-link>
                    <a class="btn btn-add" @click="save">
                      Salvar
                      <i class="fa fa-save"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  data() {
    return {
      motivos: {
        motivo_transporte_nome: "",
        flag_procedimento: 0,
        status: "",
        tipo_transportes: [],
        tipo_motivo_transportes: [],
      },
      tipo_motivo_transportes: [],
      tipo_transportes: [],
      tipo_transporte_selecionado: {},
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "motivo_transporte";

      if (self.motivos.motivo_transporte_id) {
        self.motivos._method = "PUT";
        api += "/" + self.motivos.motivo_transporte_id;
      }

      axios
        .post(api, self.motivos)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/motivo_transporte");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    incluirTipo() {
      const self = this;

      if (!self.tipo_transporte_selecionado.tipo_transporte_id) {
        self.$message(null, "Você precisa escolher um Tipo", "error");
        return;
      }

      self.motivos.tipo_transportes.push(self.tipo_transporte_selecionado);

      self.tipo_transporte_selecionado = {};
    },
    searchTipo(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading, search, vm) => {
      const api =
        vm.$store.state.api +
        "tipo_transportes?limit=50&tipo_transporte_id=" +
        search;

      axios
        .get(api)
        .then((response) => {
          vm.tipo_transportes = response.data.data;
        })
        .then(() => {
          loading(false);
        })
        .catch((error) => {
          vm.$toast.error(error.response.data.message);
        });
    }, 700),
    removerTipo(index) {
      const self = this;
      self.motivos.tipo_motivo_transportes.splice(index, 1);
    },
    getMotivos: function (id) {
      const self = this;
      const api =
        self.$store.state.api +
        "motivo_transporte/" +
        id +
        "?with[]=tipo_motivo_transportes";

      axios
        .get(api)
        .then((response) => {
          self.motivos = response.data.data[0];
          self.motivos.flag_procedimento =
            self.motivos.flag_procedimento == "0" ? 0 : 1;

          var tipo_transportes = self.motivos.tipo_transportes;
          self.motivos.tipo_transportes = [];

          tipo_transportes.forEach((element) => {
            self.motivos.tipo_transportes.push(element.tipo_transporte);
          });
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTipoMotivo: function (id) {
      const self = this;
      let api =
        self.$store.state.api + "tipo_transportes?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.tipo_transportes = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    self.getTipoMotivo();

    let id = self.$route.params.id;
    if (id) {
      self.getMotivos(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>