import Crud from '../views/Estabelecimento/Crud'
import Entity from '../views/Estabelecimento/Add'

let routes = {
    path: '/estabelecimento',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/estabelecimento/add',
            name: 'NewCbo',
            component: Entity
        },
        {
            path: '/estabelecimento/edit/:id?',
            name: 'EditCbo',
            component: Entity
        },
        {
            path: '/estabelecimento',
            name: 'list-estabelecimento',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Estabelecimentos',
                breadcrumb: [
                    {
                        text: 'Estabelecimentos',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
