<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                {{ configuracoes.configuracao_id ? "Editar" : "Adicionar" }}
                Configuração
              </div>
            </div>
            <div class="card-body">
              <hr />
              <br />
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <!-- <div class="form-row">
                      <div class="form-group col-md-4">
                        <label for="Name">
                          <span class="required">*</span> Status:
                        </label>
                        <select
                          v-model="configuracoes.status"
                          class="form-control"
                        >
                          <option :value="1">Ativo</option>
                          <option :value="0">Inativo</option>
                        </select>
                      </div>
                    </div> -->
                    <div class="row">
                      <div class="form-group col-md-4">
                        <div class="custom-control custom-switch">
                          <input
                            autocomplete="new-password"
                            v-model="configuracoes.judicial"
                            placeholder=""
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch1"
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch1"
                            >Exibir a label "É judicial"</label
                          >
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <div class="custom-control custom-switch">
                          <input
                            autocomplete="new-password"
                            v-model="configuracoes.sinais_vitais"
                            placeholder=""
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch2"
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch2"
                            >Exibir a label "Sinais Vitais"</label
                          >
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <div class="custom-control custom-switch">
                          <input
                            autocomplete="new-password"
                            v-model="configuracoes.dificuldade_acesso"
                            placeholder=""
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch3"
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch3"
                            >Exibir a label "Paciente com dificuldade de
                            acesso"</label
                          >
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <div class="custom-control custom-switch">
                          <input
                            autocomplete="new-password"
                            v-model="configuracoes.recorrencia"
                            placeholder=""
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch4"
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch4"
                            >
                            Habilitar "Recorrência" de paciente
                            </label
                          >
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <div class="custom-control custom-switch">
                          <input
                            autocomplete="new-password"
                            v-model="configuracoes.tipo_destino"
                            placeholder=""
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch5"
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch5"
                            >
                            Habilita "Tipo de destino" na solicitação
                            </label
                          >
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <div class="custom-control custom-switch">
                          <input
                            autocomplete="new-password"
                            v-model="configuracoes.label_tipo_solicitacao"
                            placeholder=""
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch6"
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch6"
                            >
                            Habilita a label "Tipo de solicitação"
                            </label
                          >
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <div class="custom-control custom-switch">
                          <input
                            autocomplete="new-password"
                            v-model="configuracoes.setor_solicitacao"
                            placeholder=""
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch7"
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch7"
                            >
                            Habilita "Setor do destino" na solicitação
                            </label
                          >
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <div class="custom-control custom-switch">
                          <input
                            autocomplete="new-password"
                            v-model="configuracoes.telefone_solicitacao"
                            placeholder=""
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch8"
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch8"
                            >
                            Habilita "Telefone" na solicitação
                            </label
                          >
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <div class="custom-control custom-switch">
                          <input
                            autocomplete="new-password"
                            v-model="configuracoes.profissional_solicitacao"
                            placeholder=""
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch9"
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch9"
                            >
                            Habilita "Profissional" na solicitação
                            </label
                          >
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <div class="custom-control custom-switch">
                          <input
                            autocomplete="new-password"
                            v-model="configuracoes.deletar_avaliacao"
                            placeholder=""
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch10"
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch10"
                            >
                            Habilita "Deletar avaliação" na solicitação
                            </label
                          >
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <div class="custom-control custom-switch">
                          <input
                            autocomplete="new-password"
                            v-model="configuracoes.cid_avaliacao"
                            placeholder=""
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch11"
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch11"
                            >
                            Habilita "CID da avaliação" na solicitação
                            </label
                          >
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <div class="custom-control custom-switch">
                          <input
                            autocomplete="new-password"
                            v-model="configuracoes.cid_antropometria"
                            placeholder=""
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch12"
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch12"
                            >
                            Habilita "CID da antropometria" na solicitação
                            </label
                          >
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <div class="custom-control custom-switch">
                          <input
                            autocomplete="new-password"
                            v-model="configuracoes.permissoes"
                            placeholder=""
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch13"
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch13"
                            >
                            Permissões de acesso
                            </label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="userPic"> Logo Empresa</label>
                      <div class="user-img">
                        <img
                          v-if="previewImage"
                          :src="previewImage"
                          class="photo"
                        />
                        <img v-else class="photo" />
                      </div>
                      <input
                        type="file"
                        class=""
                        id="userPic"
                        accept="image/*"
                        name="userPic"
                        @change="uploadImage"
                        placeholder
                      />
                    </div>
                    <span class="required_fields">
                      <span class="required">*</span>
                      <strong>Campos obrigatórios</strong>
                    </span>
                    <div class="block text-right">
                      <router-link
                        to="/configuracoes"
                        class="btn btn-back mr-1"
                      >
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      companies: [],
      departments: [],
      configuracoes: {
        judicial: 0,
        sinais_vitais: 0,
        dificuldade_acesso: 0,
        recorrencia: 0,
        tipo_destino: 0,
        label_tipo_solicitacao: 0,
        setor_solicitacao: 0,
        telefone_solicitacao: 0,
        profissional_solicitacao: 0,
        deletar_avaliacao: 0,
        cid_avaliacao: 0,
        cid_antropometria: 0,
        // permissoes: 0,
      },
      companies: {},
      departments: {},
      perfil_usuarios: [],

      errors: undefined,

      previewImage: null,
    };
  },
  computed: {},
  methods: {
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let photo = document.getElementById("userPic");

      fd.append("judicial", self.configuracoes.judicial ? "1" : "0");
      fd.append("sinais_vitais", self.configuracoes.sinais_vitais ? "1" : "0");
      fd.append(
        "dificuldade_acesso",
        self.configuracoes.dificuldade_acesso ? "1" : "0"
      );
      fd.append(
        "recorrencia",
        self.configuracoes.recorrencia ? "1" : "0"
      );
      fd.append(
        "tipo_destino",
        self.configuracoes.tipo_destino ? "1" : "0"
      );
      fd.append(
        "label_tipo_solicitacao",
        self.configuracoes.label_tipo_solicitacao ? "1" : "0"
      );
      fd.append(
        "setor_solicitacao",
        self.configuracoes.setor_solicitacao ? "1" : "0"
      );
      fd.append(
        "telefone_solicitacao",
        self.configuracoes.telefone_solicitacao ? "1" : "0"
      );
      fd.append(
        "profissional_solicitacao",
        self.configuracoes.profissional_solicitacao ? "1" : "0"
      );
      fd.append(
        "deletar_avaliacao",
        self.configuracoes.deletar_avaliacao ? "1" : "0"
      );
      fd.append(
        "cid_avaliacao",
        self.configuracoes.cid_avaliacao ? "1" : "0"
      );
      fd.append(
        "cid_antropometria",
        self.configuracoes.cid_antropometria ? "1" : "0"
      );
      fd.append("permissoes",self.configuracoes.permissoes ? "1" : "0");

      fd.append("_method", "POST");

      fd.append("logo_empresa", photo.files[0] ? photo.files[0] : "");

      if (self.configuracoes.id) {
        fd.append("id", self.configuracoes.id);
        fd.append("_method", "PUT");
      }

      return fd;
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "configuracoes";

      let fd = self.makeFormData();

      if (self.configuracoes.id) {
        api += "/" + self.configuracoes.id;
      }

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/configuracoes");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getConfiguracoes: function (id) {
      const self = this;
      const api = self.$store.state.api + "configuracoes/" + id;

      axios
        .get(api)
        .then((response) => {
          self.configuracoes = response.data.data[0];
          self.configuracoes.judicial =
            self.configuracoes.judicial == "0" ? 0 : 1;
          self.configuracoes.sinais_vitais =
            self.configuracoes.sinais_vitais == "0" ? 0 : 1;
          self.configuracoes.dificuldade_acesso =
            self.configuracoes.dificuldade_acesso == "0" ? 0 : 1;
          self.configuracoes.recorrencia = 
            self.configuracoes.recorrencia == "0" ? 0 : 1;
          self.configuracoes.tipo_destino = 
            self.configuracoes.tipo_destino == "0" ? 0 : 1;
          self.configuracoes.label_tipo_solicitacao = 
            self.configuracoes.label_tipo_solicitacao == "0" ? 0 : 1;
          self.configuracoes.setor_solicitacao = 
            self.configuracoes.setor_solicitacao == "0" ? 0 : 1;
          self.configuracoes.telefone_solicitacao = 
            self.configuracoes.telefone_solicitacao == "0" ? 0 : 1;
          self.configuracoes.profissional_solicitacao = 
            self.configuracoes.profissional_solicitacao == "0" ? 0 : 1;
          self.configuracoes.deletar_avaliacao = 
            self.configuracoes.deletar_avaliacao == "0" ? 0 : 1;
          self.configuracoes.cid_avaliacao = 
            self.configuracoes.cid_avaliacao == "0" ? 0 : 1;
          self.configuracoes.cid_antropometria = 
            self.configuracoes.cid_antropometria == "0" ? 0 : 1;
          self.configuracoes.permissoes 
            self.configuracoes.permissoes == "0" ? 0 : 1;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };
    },
  },
  mounted: function () {
    const self = this;

    let id = self.$route.params.id;
    if (id) {
      self.change_password = false;
      self.getConfiguracoes(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>