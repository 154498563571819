import Crud from '../views/Rejeicao/Crud'
import Entity from '../views/Rejeicao/Add'

let routes = {
    path: '/rejeicoes',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/rejeicoes/add',
            name: 'NewRejeicao',
            component: Entity
        },
        {
            path: '/rejeicoes/edit/:id?',
            name: 'EditRejeicao',
            component: Entity
        },
        {
            path: '/rejeicoes',
            name: 'list-rejeicoes',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Rejeições',
                breadcrumb: [
                    {
                        text: 'Rejeicao',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
