import Vue from 'vue'
import VueRouter from 'vue-router'

import User from './user'
import Solicitacao from './solicitacao'
import TipoAvaliacaoClinica from './tipo_avaliacao_clinica'
import TipoAcompanhante from './tipo_acompanhante'
import Programacoes from './programacoes'
import Cid from './cid'
import MotivoTransporte from './motivo_transporte'
import TipoTransporte from './tipo_transporte'
import TipoAuxilioLocomocao from './tipo_auxilio_locomacao'
import TipoDomicilio from './tipo_domicilio'
import TipoUnidadeSaude from './tipo_unidade_saude'
import TipoVeiculo from './tipo_veiculo'
import Veiculo from './veiculo'
import Paciente from './paciente'
import Cbo from './cbo'
import Municipio from './municipio'
import Uf from './uf'
import NaturezaTransporte from './natureza_transporte'
import SituacaoTransporte from './situacao_transporte'
import UnidadeSaude from './unidade_saude'
import Profile from './profile'
import PerfilUsuario from './perfil_usuario'
import Produto from './produto'
import TipoCuidado from './tipo_cuidado'
import Estabelecimento from './estabelecimento'
import TipoEstabelecimento from './tipo_estabelecimento'
import CategoriaProduto from './categoria_produto'
import TipoUnidadeMedida from './tipo_unidade_medida'
import Configuracao from './configuracao'
import Cancelamento from './cancelamento'
import Rejeicao from './rejeicao'
import Log from './log'
import Cancelado from './cancelado'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    User,
    Solicitacao,
    TipoAvaliacaoClinica,
    TipoAcompanhante,
    Programacoes,
    Cid,
    MotivoTransporte,
    TipoTransporte,
    TipoAuxilioLocomocao,
    TipoDomicilio,
    TipoUnidadeSaude,
    TipoVeiculo,
    Veiculo,
    Paciente,
    Cbo,
    Municipio,
    Uf,
    NaturezaTransporte,
    SituacaoTransporte,
    UnidadeSaude,
    Profile,
    PerfilUsuario,
    Produto,
    TipoCuidado,
    Estabelecimento,
    TipoEstabelecimento,
    CategoriaProduto,
    TipoUnidadeMedida,
    Configuracao,
    Cancelamento,
    Rejeicao,
    Log,
    Cancelado,

    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  const perfil_id = localStorage.getItem('perfil_id');
  const authenticated = localStorage.getItem('token');
  let to_splited = to.path.toString().split('/')
  to_splited = to_splited.filter(part => part !== '')

  if (
    !authenticated &&
    (
      !(['/login'].includes(to.path.toString())) &&
      !(['public'].includes(to_splited[0]))
    )
  ) {
    next('/login');

    return true;
  }

  if (to.meta.perfil_id) {
    if (!to.meta.perfil_id.includes(perfil_id)) {
      next('/');
    }
  }

  next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
